/* #### Auth #### */
.auth-pg {
  min-height: 100vh;

  .auth-banner-img {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
    max-height: calc(100vh - 40px);
    object-fit: cover;
    aspect-ratio: 4 / 5;
  }
}

/* ############## */

/* #### Sidebar #### */
.cus-sidebar {
  ul {
    .active {
      li {
        flex-wrap: wrap;

        >div {
          color: $primary !important;

          svg {
            path {
              fill: $primary;
            }

            color: $primary;
          }

          .MuiIcon-root {
            color: $primary;
          }
        }

        >ul.MuiList-root {
          width: 100%;
          padding-left: 1.5rem;

          .MuiTypography-root {
            line-height: 1.25;
          }
        }

        span {
          font-size: 14px;
        }
      }
    }

    >li {
      flex-direction: column;

      >ul.MuiList-root {
        width: 100%;
        padding-left: 1.5rem;

        .collapse-menu-item {
          padding: 0.5rem 1rem;
        }

        .MuiTypography-root {
          line-height: 1.25;
        }
      }

      span {
        font-size: 14px;
      }
    }

    a {
      li {
        span {
          font-size: 14px;
        }
      }
    }
  }
}

/* ################# */

/* #### Header #### */
header {
  &.header-sec {
    position: fixed;
    border-radius: 0;
    top: 0 !important;
    min-height: 3.125rem;
    z-index: 1201;
    padding: 0;

    .cus-toolbar {
      >div {
        flex: 1;

        &:nth-child(2) {
          flex: auto;
        }
      }
    }
  }
}

/* ################ */

/* #### Form Controls #### */
body {
  .cus-filter-tabs {
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    .MuiTabs-flexContainer {
      width: 100%;
      display: inline-block;

      button {
        width: auto;
        font-weight: 700;
        letter-spacing: -0.144px;
        border-radius: 0;
        border-bottom: 2px solid transparent;
        padding: 16px 25px;
        display: inline-block;
        text-align: center;

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  .cus-sub-tabs {
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    .MuiTabs-flexContainer {
      width: 100%;
      display: inline-block;

      button {
        width: auto;
        font-weight: 700;
        letter-spacing: -0.144px;
        border-radius: 0;
        border-bottom: 2px solid transparent;
        padding: 16px 25px;
        display: inline-block;
        text-align: center;

        .MuiTouchRipple-root {
          display: none;
        }

        &.Mui-selected {
          color: $primary !important;
          border-color: $primary;
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }


  .form-select {
    position: relative;

    &:after {
      content: "";
      background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.5859 3.59846C15.0338 3.06711 14.138 3.06711 13.5852 3.59846L8.00023 9.51695L2.41522 3.59846C1.86244 3.06711 0.967336 3.06711 0.414584 3.59846C-0.138195 4.12981 -0.138195 4.99048 0.414584 5.52183L6.91135 12.4071C7.21038 12.6942 7.60865 12.8208 8.00026 12.7972C8.39118 12.8208 8.79013 12.6942 9.08913 12.4071L15.5859 5.52183C16.138 4.99048 16.138 4.12984 15.5859 3.59846Z" fill="%239A9AAF"/></svg>');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 14px;
      height: 14px;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      pointer-events: none;
    }

    .MuiSelect-multiple {
      width: calc(100% - 75px);

      &:focus {
        background-color: $white;
      }
    }

    &.days-form-select {
      .MuiSelect-multiple {
        width: 100%;
        padding-right: 2.25rem !important;

        &:before {
          content: "";
          display: block;
          width: 2.5rem;
          height: calc(100% - 2px);
          background-color: $white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2px;
          border-radius: 10rem;
        }
      }
    }

    &.form-dropdown {
      &:after {
        background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 11L3 6H13L8 11Z" fill="%230062FF"/></svg>');
        width: 18px;
        height: 18px;
        margin-top: -1px;
      }

      .MuiInputBase-input {
        padding: 5px 15px !important;
        font-size: 13px;
        font-weight: 600;
        color: $grey-800;
        background: $grey-100;
      }

      fieldset {
        border-color: $grey-100;
      }

      &:hover {
        fieldset {
          border-color: $white-400;
        }
      }
    }
  }

  .form-filter-select {
    .filter-icon {
      position: absolute;
      right: 1rem;
      pointer-events: none;
    }

    .MuiInputBase-root {
      background-color: $grey-100;
      padding-right: 0;
    }

    .MuiInputBase-input {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      font-size: 14px;
      font-weight: 500;
      background-color: $grey-100;
      padding-right: 2.5rem !important;
    }

    .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
      margin-top: -4px;
      font-weight: 500;
    }

    .MuiFormLabel-root:is(.MuiInputLabel-shrink)+.MuiInputBase-root .MuiSvgIcon-root {
      color: $primary;
    }

    fieldset {
      border-color: $grey-400;
    }
  }

  .form-chip-checkbox {
    margin: 0;
    border-radius: 50px;
    padding: 10px 24px;
    display: inline-flex;
    gap: 6px;
    background-color: $white;

    &.chip-success {
      background-color: rgba(36, 202, 73, 0.10);
    }

    &.chip-error {
      background-color: rgba(253, 68, 56, 0.10);
    }

    .MuiCheckbox-root {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-weight: 400;
      line-height: 1.5;
      color: $grey-800;
    }
  }

  .quill-text-editor {
    .ql-toolbar {
      padding: 0px 2px 4px 2px;
      background: $grey-200;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .ql-container {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      font-size: 16px;

      .ql-editor[contenteditable="true"] {
        min-height: 120px;
      }

      .ql-editor {
        padding: 16px;
      }
    }
  }

  .quill-text-editor-view {

    ul,
    ol {
      padding-left: 30px;
      margin-bottom: 0.5em;
    }

    h1,
    h2,
    h3 {
      line-height: 1.25;
      margin-bottom: 0.5em;
    }

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      margin-bottom: 0.5em;
      font-size: 16px;
    }
  }
}

.form-input {
  &.form-input-dark-gray {
    .MuiInputBase-formControl {
      background-color: $grey-400;

      .MuiInputBase-input:not(:focus) {
        background-color: $grey-400;
      }

      &:not(.Mui-focused) fieldset {
        border-color: $grey-400;
      }
    }

    .MuiFormLabel-root:is(.MuiInputLabel-shrink)+.MuiInputBase-formControl .MuiInputBase-input:not(:focus) {
      background-color: $grey-100;
    }

    .MuiFormLabel-root:is(.MuiInputLabel-shrink)+.MuiInputBase-formControl:not(.Mui-focused) fieldset {
      border-color: $grey-100;
    }

    .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
      color: $white;
    }
  }

  &.created-by {
    background-color: $grey-400;
    &.p-l-field {
      background-color: transparent;
      .MuiInputBase-root {
        input {
          padding-left: 20px;
          background: #f4f4f6;
        }
      }
    }

    .MuiInputBase-input {
      padding-left: 2px;
    }

    .MuiInputAdornment-root {
      opacity: .75;
    }
  }

  &:not(.created-by) {
    .Mui-disabled {
      .MuiInputBase-input {
        background-color: $grey-100;
      }
    }
  }

  .MuiInputBase-multiline {
    .MuiInputBase-inputMultiline {
      border-radius: 20px;
    }

    fieldset {
      border-radius: 20px;
    }
  }

  &.currency-field {
    .MuiInputBase-adornedStart {
      padding-left: 1.25rem;

      .MuiInputBase-inputAdornedStart {
        padding-left: 0.25rem;
      }
    }
  }
}

.form-input-upload {
  .MuiInputBase-root {
    padding-right: 4px;
  }
}

.MuiAutocomplete-inputRoot {
  padding: 0.75rem 1.25rem !important;

  .MuiAutocomplete-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.MuiInputBase-adornedEnd {
  .MuiAutocomplete-input {
    padding-right: 20px !important;
  }
}

.search-field {
  .MuiInputBase-formControl {
    border-radius: 9999px;
    padding: 0;

    &:not(.Mui-focused) {
      fieldset {
        border-color: $white;
      }
    }

    .MuiInputBase-input {
      padding-left: 40px;
      background-color: $white;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  .MuiInputAdornment-root {
    position: absolute;
    left: 10px;
    top: auto;
  }
}

.mobile-time-picker {
  .MuiInputBase-input {
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.63 17.17L17.17 15.63L13.1 11.56V6.5H10.9V12.44L15.63 17.17ZM12 23C10.4783 23 9.04833 22.7112 7.71 22.1337C6.37167 21.5562 5.2075 20.7725 4.2175 19.7825C3.2275 18.7925 2.44375 17.6283 1.86625 16.29C1.28875 14.9517 1 13.5217 1 12C1 10.4783 1.28875 9.04833 1.86625 7.71C2.44375 6.37167 3.2275 5.2075 4.2175 4.2175C5.2075 3.2275 6.37167 2.44375 7.71 1.86625C9.04833 1.28875 10.4783 1 12 1C13.5217 1 14.9517 1.28875 16.29 1.86625C17.6283 2.44375 18.7925 3.2275 19.7825 4.2175C20.7725 5.2075 21.5562 6.37167 22.1337 7.71C22.7112 9.04833 23 10.4783 23 12C23 13.5217 22.7112 14.9517 22.1337 16.29C21.5562 17.6283 20.7725 18.7925 19.7825 19.7825C18.7925 20.7725 17.6283 21.5562 16.29 22.1337C14.9517 22.7112 13.5217 23 12 23ZM12 20.8C14.4383 20.8 16.5146 19.9429 18.2287 18.2287C19.9429 16.5146 20.8 14.4383 20.8 12C20.8 9.56167 19.9429 7.48542 18.2287 5.77125C16.5146 4.05708 14.4383 3.2 12 3.2C9.56167 3.2 7.48542 4.05708 5.77125 5.77125C4.05708 7.48542 3.2 9.56167 3.2 12C3.2 14.4383 4.05708 16.5146 5.77125 18.2287C7.48542 19.9429 9.56167 20.8 12 20.8Z" fill="%239A9AAF"/></svg>');
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 18px;
    cursor: pointer;
  }

  .MuiInputBase-formControl {
    padding: 0;
  }
}

.MuiPickersLayout-root {
  .MuiPickersToolbar-root {
    .MuiPickersToolbar-content {
      .MuiTimePickerToolbar-ampmSelection {
        .MuiButtonBase-root {
          min-height: 1.5rem;
        }
      }
    }
  }
}

.custom-search-field {

  .MuiInputBase-adornedStart,
  .MuiInputBase-adornedEnd {
    padding-left: 10px;
    padding-right: 10px;

    .MuiInputBase-input {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }

  .MuiInputBase-formControl {
    &:not(.Mui-focused) {
      fieldset {
        border-color: $white;
      }
    }

    .MuiInputBase-input {
      background-color: $white;

      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.react-datepicker-wrapper {
  display: flex;

  input {
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    width: 100%;
    font-size: 1rem;
    color: $grey-800;
    background-color: $white-50;
    border: 1px solid $grey-50;
    outline: none;
    box-shadow: none;
    height: auto;
    min-height: 47px;
    cursor: pointer;

    &:hover {
      border-color: $grey-500;
    }
  }

  .react-datepicker__calendar-icon {
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0.5rem;
    color: $grey-400;
    pointer-events: none;
    padding: .5rem;
  }

  .react-datepicker__close-icon {
    padding: 0;
    margin: 0 2.5rem 0 0;

    &:after {
      background-color: $white;
      color: $grey-400;
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.5rem;
      content: "×";
    }

    &:hover {
      &:after {
        color: $grey-500;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 9;

  .react-datepicker {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    color: $grey-800;
    border-color: $white-300;
    border-radius: 8px;
  }

  .react-datepicker__header {
    background-color: $grey-200;
    border-color: $white-300;
    border-top-left-radius: 8px;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 8px;
    border-top-left-radius: 0px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: $grey-800;
    font-weight: 700;
    font-size: 14px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $grey-800;
  }

  .react-datepicker__day-name {
    font-weight: 600;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 50px;
    background-color: $primary;
    color: $white;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50px;
    background-color: $primary;
    color: $white;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    border-radius: 50px !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    border-bottom-right-radius: 8px;
    border-right: 1px solid $white-300;
  }

  .react-datepicker__time-container {
    border-color: $white-300;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-lis::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-lis::-webkit-scrollbar-track {
    background: $grey-100;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-lis::-webkit-scrollbar-thumb {
    background: $white-500;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-lis::-webkit-scrollbar-thumb:hover {
    background: $grey-600;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: auto;
    padding: 8px 10px;
  }
}

/* ###################### */

/* #### Chip #### */
.MuiChip-root {
  height: auto !important;
  border-radius: 50px !important;

  &.chip-square {
    border-radius: 6px !important;
    background-color: $white;
  }

  &.MuiChip-colorSuccess {
    background-color: rgba(36, 202, 73, 0.10);
    color: $grey-800;
  }

  &.MuiChip-colorError {
    background-color: rgba(253, 68, 56, 0.10);
    color: $grey-800;
  }

  .MuiChip-label {
    font-weight: 400;
    line-height: 1.5;
    padding: 6px 16px;
    text-align: center;

    &.MuiChip-labelSmall {
      font-size: 12px;
    }

    &.MuiChip-labelMedium {
      min-width: 112px;
    }
  }

  &.promo-chip {
    &.MuiChip-colorSuccess {
      background-color: #24CA490A;
      color: $green-300;
    }

    &.MuiChip-colorWarning {
      background-color: #FE7E070A;
      color: $orange-100;
    }

    &.MuiChip-colorError {
      background-color: #FD44380A;
      color: $red-200;
    }

    &.Mui-disabled {
      filter: grayscale(1);
      opacity: 1;
    }

    .MuiChip-label {
      font-weight: 500;
      padding: 3px 12px;
      min-width: auto;
    }
  }
}

.invite-action {
  .MuiButtonBase-root {
    font-weight: 600;
    border-width: 1px;
    min-width: 100px;

    &.MuiChip-colorSuccess {
      color: $green-300;
      background-color: transparent;

      &:hover {
        background-color: $green-300 !important;
        color: $white;
      }
    }

    &.MuiChip-colorError {
      color: $red-200;
      background-color: transparent !important;

      &:hover {
        background-color: $red-200 !important;
        color: $white;
      }
    }

    &.MuiChip-colorWarning {
      color: $primary;
      border-color: $primary;
      background-color: transparent !important;

      &:hover {
        background-color: $primary !important;
        color: $white;
      }
    }
  }
}

/* ############## */

/* #### Pagination #### */
.cus-pagination {
  .MuiPagination-ul {
    justify-content: end;

    li {
      .MuiPaginationItem-root {
        background-color: $grey-200;
        border-radius: 6px;
        color: $grey-500;
        font-weight: 500;
        line-height: 1.25;
        margin: 0 6px;

        &.MuiPaginationItem-sizeSmall {
          font-size: 12px;
        }

        &.MuiPaginationItem-previousPrev,
        &.MuiPaginationItem-previousNext {
          font-size: 18px;
          color: $grey-800;
          opacity: 1;

          &:disabled {
            .MuiPaginationItem-icon {
              opacity: .3;
            }
          }
        }

        &.Mui-selected {
          color: $primary;
        }
      }
    }
  }
}

/* #################### */

.ag-grid--table {
  .ag-root-wrapper {
    border-radius: 0 0 8px 8px;
    border: 0;
    background: transparent;
  }

  .ag-header {
    background-color: $grey-300;
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    box-shadow: none;

    .ag-header-cell {
      font-family: 'Inter', sans-serif;

      &.center-aligned-cell-header {
        .ag-header-cell-label {
          justify-content: center;
        }
      }

      &.right-aligned-cell-header {
        .ag-header-cell-label {
          justify-content: end;
        }
      }

      &.left-aligned-cell-header {
        .ag-header-cell-label {
          justify-content: start;
        }
      }

      &.ag-column-last {
        div {
          justify-content: end;
        }

      }
    }

    .ag-header-cell-label {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $grey-500;
    }
  }

  .ag-row {
    border-bottom: 1px solid $grey-100;
    border-bottom: 1px solid $grey-100;

    .ag-cell {
      font-size: 12px;
      font-weight: 400;
      overflow: visible;
      vertical-align: middle;
      font-family: 'Inter', sans-serif;
      background-color: $white;
      padding-top: 6px;
      padding-bottom: 6px;
      display: inline-flex;
      align-items: center;

      &.ag-column-first {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.ag-column-last {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .ag-cell-value,
      .ag-group-value {
        overflow: visible !important;
      }
    }
  }

  .ag-pinned-right-header,
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border-left: 1px solid $grey-100;
  }

  .ag-row-hover:not(.ag-full-width-row)::before,
  .ag-row-hover.ag-full-width-row.ag-row-group::before {
    border-radius: 8px;
    opacity: .4;
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border-color: transparent;
    outline-color: transparent;
  }

  .ag-pinned-right-cols-container .ag-row-hover:not(.ag-full-width-row)::before,
  .ag-pinned-right-cols-container .ag-row-hover.ag-full-width-row.ag-row-group::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ag-pinned-left-cols-container+.ag-center-cols-viewport .ag-center-cols-container .ag-row-hover:not(.ag-full-width-row)::before,
  .ag-pinned-left-cols-container+.ag-center-cols-viewport .ag-center-cols-container .ag-row-hover.ag-full-width-row.ag-row-group::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.ag-theme-quartz-dark {
  .ag-grid--table {
    .ag-header {
      background-color: $black-800;

      .ag-header-cell-label {
        color: $black-400;
      }
    }

    .ag-row {
      border-color: $black-700;

      .ag-cell {
        background-color: $black;
        color: $white-700;


        &.ag-column-first {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.ag-column-last {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.task-start {
        .ag-cell {
          background-color: #241E30;
        }
      }

      &.task-return {
        .ag-cell {
          background-color: #2A2318;
        }
      }

      &.task-break {
        .ag-cell {
          background-color: #11292B;
        }
      }

    }

    .ag-pinned-right-header,
    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
      border-color: $black-700;
      ;
    }

    .MuiIconButton-root {
      background-color: $grey-900;
      border-radius: 5px;
    }

    .color-return-to-hq {
      color: #FFB21E;

      svg,
      * {
        color: #FFB21E;
        fill: #FFB21E;
      }
    }

    .color-start-task {
      color: #A162F7;

      svg,
      * {
        color: #A162F7;
        fill: #A162F7;
      }
    }

    .color-break-task {
      color: #05EFE1;

      svg,
      * {
        color: #05EFE1;
        fill: #05EFE1;
      }
    }

  }
}

.truncate {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.line {
    &-1 {
      -webkit-line-clamp: 1;
    }

    &-2 {
      -webkit-line-clamp: 2;
    }

    &-3 {
      -webkit-line-clamp: 3;
    }
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body {
  .btn-primary-light {
    background: $grey-700;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $primary;
    border-radius: 8px;

    &:hover,
    &:focus:not(:hover) {
      background: $grey-700;
      color: $primary;
    }
  }

  .card-footer-action {
    border-radius: 10px;
    border-top: 1px solid $grey-100;

    button {
      border-color: transparent;
      border-radius: 0;
      padding: 14px;
      font-weight: 400;

      &:not(:last-child) {
        border-right-color: $grey-100 !important;
      }

      &:hover {
        border-color: transparent;
      }

      &:active:not(:hover) {
        background-color: transparent;
      }
    }
  }

  .filter-tabs {
    button {
      border-radius: 6px;
      font-weight: 600;

      &:not(.tab-active) {
        color: $grey-400;
      }
    }
  }

  .MuiAlert-root {
    padding: 2px 16px 2px 12px;
    font-size: 0.75rem;
    font-weight: 500;

    .MuiAlert-icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .MuiDrawer-root {
    z-index: 1202;
  }

  .cus-drawer {
    .MuiDrawer-paper {
      padding: 24px;
      -webkit-backdrop-filter: blur(7px);
      backdrop-filter: blur(7px);
      background: #f1f1f15c;
      box-shadow: none;
      height: calc(100vh - 68px);
      top: 68px;
      width: 400px !important;

      div {
        // width: 100%;
      }
    }
  }
}

.row-pointer,
.row-cursor {

  .ag-theme-quartz-dark,
  &.ag-theme-quartz-dark {
    .ag-body {
      .ag-center-cols-viewport {
        .ag-row {
          &:hover {
            .ag-cell {
              background-color: #000000fe;
            }
          }
        }
      }
    }
  }
}


/* #### Loader #### */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: $primary;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em
  }

  40% {
    box-shadow: 0 2.5em 0 0
  }
}

/* ################ */


.notes-modal {
  .MuiDialog-container {
    justify-content: end;

    .MuiDialog-paper {
      max-width: 380px;
    }
  }

  .notes-modal-body {
    ul {
      li {
        justify-content: space-between;
      }

      .MuiDivider-root {
        margin: 12px 0;
      }
    }

    .MuiChip-label {
      font-weight: 600;
      padding: 6px 20px;
    }
  }
}

.MuiSnackbar-root {
  >.MuiBox-root {
    box-shadow: 0rem 0.25rem 0.375rem -0.025rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.025rem rgba(0, 0, 0, 0.05), 0 0 0.5px 2px rgb(0 0 0 / 5%);
  }
}

.custom-zoom [data-rmiz-modal-overlay="visible"] {
  background-color: rgb(212 194 194) I !important;
  opacity: 0.8;
}

.MuiIcon-root {
  svg {
    width: inherit;
    height: inherit;
  }
}

.pro-avatar-sec {
  .MuiAvatar-root {
    background-color: $grey-100;
    padding: 10px;
  }
}

.MuiAutocomplete-hasPopupIcon {
  .MuiAutocomplete-endAdornment {
    padding-left: 2px;

    .MuiAutocomplete-clearIndicator,
    .MuiAutocomplete-popupIndicator {
      background: $white;
    }
  }
}

.address-field {
  position: relative;

  .location-search-input {
    .MuiInputAdornment-root {
      button {
        padding: 0;
        margin-right: 2px;
      }
    }
  }

  .autocomplete-dropdown-container {
    margin: 0 10px;
    background-color: $grey-100;
    position: absolute;
    top: 67px;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, 0);
    z-index: 22;

    >div {
      padding: 5px 10px;
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 16px !important;
    }

    .suggestion-item,
    .suggestion-item--active {
      width: 100%;
      display: inline-block;
      text-align: left;
      background-color: $grey-100 !important;
      padding: 5px 15px;
      border-bottom: 1px solid $white;
      min-height: auto;

      span {
        font-size: 16px !important;
      }

      &:hover {
        background-color: $grey-200 !important;
      }
    }
  }
}

/* Manage Categories Css Start */
.manage-cat-sec {
  ul {
    padding-top: 10px;

    li {
      align-items: center;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      .category-img-sec {
        width: 60px;
        height: 50px;

        img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.375rem;
        }
      }

      .sub-category-name-sec,
      .left-section {
        flex: 1;
        padding: 11px 14px;
        position: relative;
        background-color: $white-100;
        border-radius: 0.375rem;
        display: flex;
        min-height: 50px;
        flex-wrap: wrap;
        row-gap: 0.5rem;

        label {
          line-height: 1.5;
          font-size: 1rem;
          margin: 0;
          font-family: "Inter", sans-serif;
          font-weight: 400;
          padding: 0 4px;
          display: block;
          transform-origin: top left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(133% - 32px);
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transform: translate(14px, -14px) scale(0.75);
          -moz-transform: translate(14px, -14px) scale(0.75);
          -ms-transform: translate(14px, -14px) scale(0.75);
          transform: translate(14px, -14px) scale(0.75);
          -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
          transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
          z-index: 1;
          pointer-events: auto;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: $grey-500;
          background-color: $white;
          border-radius: 0.375rem;
        }

        .MuiBadge-root {
          padding: 0.2rem 0.625rem;
          background-color: $white;
          color: $grey-800;
          border-radius: 0.375rem !important;
          box-shadow: none;
          font-size: 0.8125rem;
        }
      }

      .left-section {
        min-width: 200px;
        flex: none;

        .MuiBadge-root {
          padding: 0;
          background-color: transparent;
          border-radius: 0 !important;
          font-size: 0.9rem;
        }
      }
    }
  }
}

/* Manage Categories Css Ends */

/* Filter Section Css Start */
.cus-filter-sec {
  background-color: $white;
  padding: 12px;
  border-radius: 8px;

  .filter-box {
    .MuiInputBase-root {
      border-radius: 8px;
      background: $white-200;

      .MuiSelect-select {
        background: transparent;
      }
    }
  }

  .filter-price-unit,
  .filter-brands,
  .filter-pro-status {
    fieldset {
      border: none;
      border-right: 50px solid $white-25 !important;
    }

    .MuiFormControl-root {
      .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
        margin-top: -5px;
        margin-left: 0;
      }

      .MuiInputLabel-root:is(.MuiInputLabel-shrink) {
        margin-left: 0;
      }
    }
  }

  .form-select {
    .MuiSelect-select {
      width: 100%;

      &.MuiInputBase-inputSizeSmall {
        padding: .5rem .75rem !important;
      }
    }
  }

  fieldset {
    border: none;
  }

  .filter-price,
  .filter-qty {
    .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
      margin-top: 0px;
      margin-left: 0;
    }

    .MuiInputLabel-root:is(.MuiInputLabel-shrink) {
      margin-top: 1px;
      margin-left: 0;
    }

    input {
      background: transparent;
      height: auto;
      min-height: 1.4375em;
      padding: 0.5rem .75rem;
    }

    fieldset {
      top: 0;
    }
  }

  .filter-btns {
    height: 100%;
    display: flex;
    align-items: center;

    button {
      &.filter-clear-all {
        font-weight: 600;
        padding-left: 0;
        padding-right: 0;
        margin-top: 2px;
        text-decoration: underline;
        text-underline-offset: 2px;
        font-size: .75rem;
        min-width: auto;
        white-space: nowrap;

        .MuiSvgIcon-root {
          font-size: 1.25rem !important;
        }
      }
    }
  }

  .filter-date-range {
    .react-datepicker-wrapper {
      padding: 0.204rem 0.75rem;
      border-radius: 8px;
      background: $white-200;

      .react-datepicker__calendar-icon {
        right: 0;
        padding: 0;
      }

      input {
        padding: 0;
        border-radius: 0;
        border: none;
        background-color: transparent;
        min-height: auto;
        font-size: 0.875rem;
        color: $grey-600;
        line-height: 1.15;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        padding-right: 2.75rem;

        &::-webkit-input-placeholder {
          color: $grey-400;
          opacity: 0.8;
        }

        &::-moz-placeholder {
          color: $grey-400;
          opacity: 0.8;
        }

        &:-ms-input-placeholder {
          color: $grey-400;
          opacity: 0.8;
        }

        &:-moz-placeholder {
          color: $grey-400;
          opacity: 0.8;
        }
      }

      .react-datepicker__close-icon {
        margin-right: 1.5rem;
        display: none;

        &::after {
          font-size: 1rem;
          width: 1rem;
          height: 1rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
        }
      }
    }

    &.selected-dates {
      .react-datepicker__close-icon {
        display: table-cell;
      }
    }
  }

}

body {
  .filter-checkbox {
    .MuiTypography-root {
      font-weight: 600;
      margin: 0;
    }
  }
}

/* Filter Section Css Ends */

/* Access Denied Page Css Start */
.access-denied-page {
  width: 100%;
  display: flex;
  align-items: center;
  max-height: 100vh;
  min-height: 100vh;
  justify-content: center;

  .access-denied-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 20px;
    max-width: 520px;

    img {
      max-width: 400px;
    }

    h1 {
      color: $grey-800;
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      margin: 30px 0 10px;
    }

    p {
      color: $grey-500;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      margin: 0 0 30px 0;
    }

    button {
      min-width: 160px;
    }
  }
}

/* Access Denied Page Css Ends */

/* Promo Applied CSS Start */
.promo-badge {
  margin-top: 4px;

  span {
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid $orange-100;
    background: rgba(255, 242, 203, 0.2);
    color: $orange-100;
    padding: 3px 10px;
    display: inline-flex;
    font-weight: 500;
  }
}

/* Promo Applied CSS Ends */

.paid-badge,
.unpaid-badge,
.suspended-badge {
  font-weight: 600;
  font-size: 12px;
  color: $green-100;
}

.unpaid-badge {
  color: $orange-100;
}

.suspended-badge {
  color: $red-100
}

.address-detail {
  display: inline;
  white-space: break-spaces;
  line-height: 1.5;
}

.notifiction-dawer {
  position: relative;
  cursor: pointer;

  .count-number {
    position: absolute;
    top: -6px;
    right: -5px;
    background: $red-200;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    line-height: 18px;
    color: $white;
    cursor: pointer;
  }
}

.notification-list {
  width: 100%;
  display: inline-block;
}

.cus-drawer {
  >div {
    background: transparent;

    .text-12 {
      color: $grey-500;
      font-size: 12px;
    }

    .cus-notification-card-main {
      width: 100%;
      max-width: 100%;

      .cus-notification-card {
        display: flex;
        width: 100%;
        padding: 0;
        margin: 12px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 10px;
        background: $white;
        border: 1px solid $grey-400;
        box-shadow: 0px 10.52px 10.017px 0px rgba(195, 194, 209, 0.06), 0px 4.65px 5.32px 0px rgba(195, 194, 209, 0.04), 0px 2.767px 2.214px 0px rgba(195, 194, 209, 0.03);

        &.card-notification {
          border-radius: 12px;
          border: 1px solid $primary;
          box-shadow: 0px 100px 80px 0px rgba(213, 216, 226, 0.02), 0px 41.778px 33.422px 0px rgba(213, 216, 226, 0.01), 0px 22.336px 17.869px 0px rgba(213, 216, 226, 0.01), 0px 12.522px 10.017px 0px rgba(213, 216, 226, 0.01), 0px 6.65px 5.32px 0px rgba(213, 216, 226, 0.01), 0px 2.767px 2.214px 0px rgba(213, 216, 226, 0.01);

          &.read {
            border-color: $grey-400;
          }

          .notification-inner {
            padding: 20px;

            .notification-icon {
              width: 30px;
              height: 30px;
              background: $grey-200;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              margin-right: 10px;

            }

            .notification-contents {
              width: calc(100% - 48px);

              p {
                display: flex;
                align-items: start;
                justify-content: space-between;
                margin-bottom: 6px;
                font-size: 12px;
                white-space: normal;
                color: $grey-800;

                span {
                  font-weight: 600;
                  word-break: break-all;
                  padding-left: 8px;

                  &.white-space-nowrap {
                    padding: 0;
                    color: $grey-500;
                    font-weight: normal;
                  }
                }
              }

              h4 {
                font-size: 16px;
                margin-bottom: 6px;
              }

              .view-btn {
                background: transparent;
                border-radius: 30px;
                padding: 5px 15px;
                border: 1px solid $primary;
                color: $primary;
                font-weight: 600;
                font-size: 12px;
                color: $grey-800;
                cursor: pointer;

                &:hover {
                  background: $primary;
                  color: $white;
                }
              }
            }

          }
        }
      }
    }
  }

  .MuiDrawer-paper {
    border-radius: 0;
  }

  &.approval-drawer {
    .MuiModal-backdrop {
      background: #2e2e3a70;
    }

    button {
      &.MuiIconButton-edgeEnd {
        position: absolute;
        right: 20px;
        top: 15px;

        svg {
          color: #2E2E3A;
        }
      }
    }

    .approval-sidebar {
      .drawer-inner-bg {
        border-radius: 10px;
        background: $grey-200;
        padding: 24px 20px;
      }
    }

    .drawer-inner-scroll {
      max-height: calc(100vh - 65px);

      &.pending-class {
        max-height: calc(100vh - 150px);
      }

      overflow: auto;
    }

    .MuiDrawer-paper {
      width: 470px !important;
      background: $white;
      top: 0;
      height: 100vh;
      padding: 0;
    }

    .hd-drivers-search {
      label {
        margin-left: 25px;

        &.Mui-focused {
          margin-left: 10px;
        }
      }
    }
  }

  .inner-scroll {
    .loading-Spinner {
      height: auto;
    }
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 4px;
  padding-top: 2px;
  cursor: pointer;
}

.notification-pop {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;

  >div {
    border-radius: 12px;
    box-shadow: 0px 100px 80px 0px rgba(213, 216, 226, 0.02), 0px 41.778px 33.422px 0px rgba(213, 216, 226, 0.01), 0px 22.336px 17.869px 0px rgba(213, 216, 226, 0.01), 0px 12.522px 10.017px 0px rgba(213, 216, 226, 0.01), 0px 6.65px 5.32px 0px rgba(213, 216, 226, 0.01), 0px 2.767px 2.214px 0px rgba(213, 216, 226, 0.01);
    z-index: 999;
    padding: 20px;
    margin-right: 20px;
    width: 340px;
    background-color: $white-800 !important;
  }

  p {
    font-size: 12px;
    line-height: normal;
    margin: 8px 0 0 !important;
    display: flex;
    justify-content: space-between;
  }

  h4 {
    font-size: 16px;
    line-height: normal;
  }

  button {
    padding: 0px 10px !important;
    font-size: 10px;
    color: $primary;
    min-height: 24px;
    margin-top: 12px;
    border: 1px solid $primary;
    font-weight: 600;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.map-custom {
  width: 265px;
  height: 230px;
}

.loading-Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading_spin {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Admin Hyper Drive Css Start */
.import-driver-modal {
  p {
    font-weight: 500;
    color: $grey-500;

    a {
      color: $primary;
    }
  }
}

.create-break {
  .form-input:not(.created-by) {
    .Mui-disabled {
      .MuiInputBase-input {
        background-color: $grey-100;
        opacity: 1 !important;
        font-weight: bold;
        pointer-events: none;
      }

      fieldset {
        border-color: $grey-400;
      }
    }
  }
}

.effects-listing {
  margin: 5px 0 10px;

  .effect-chkbox {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px 0;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}

.last-sync {
  padding: 12px 20px;
  align-items: center;
  background-color: $black-500;
  margin-bottom: 20px;
  font-weight: 500;
  color: $grey-900;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 10px;

  .product-sync-div {
    background-color: $grey-900;
    color: $white;
    align-items: center;
    height: 35px;
    display: flex;
    justify-content: center;
    width: 120px;
    font-size: 14px;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    min-width: 160px;

    svg {
      color: $green-400;
    }

    &.disabled-text {
      color: $green-50;
      cursor: default !important;
    }
  }
}

.tabs-section-div {
  margin: 10px auto;
  width: 280px;
  display: flex;
  gap: 15px;

  div {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0;
    color: $grey-800;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 0;
    flex: 1 1;
    border-bottom: 2px solid transparent;

    &.selected-tab {
      cursor: default !important;
      color: $primary;
      border-color: $primary;
    }
  }
}

.tabs-section-btn {
  margin: 10px auto;
}

.category-ul-list {
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    &.custon-list {
      font-size: 16px;
      width: 100%;
      display: block;
      border-radius: 4px;
      position: relative;
      text-transform: capitalize;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      span.menu-icon {
        position: absolute;
        right: 16px;
        top: 15px;
      }

      .MuiChip-root {
        background-color: $primary;

        .MuiChip-label {
          color: $white;
        }

        .MuiChip-deleteIcon {
          color: rgba(255, 255, 255, 0.7);

          &:hover {
            color: $white;
          }
        }
      }
    }

    .category-img-sec {
      height: 60px;
      width: 60px;
      border: 0px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding: 5px;
      align-items: center;

      .category-img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        margin-right: 12px;
      }
    }

    .left-section {
      margin-right: 10px;
      width: 300px;
    }

    .category-sec-list-drag {
      padding: 10px;
    }
  }
}

.ha-field-design {
  .MuiSelect-select {
    font-size: 14px;
  }
}

.page-res-head {
  button {
    &.MuiButton-contained {
      min-width: 160px;
    }
  }
}

/* Admin Hyper Drive Css Ends */

/* Hyper Drive Css Start */
.hd-wrapper {
  .content-height {
    height: calc(100vh - 50px);
    background: $grey-900;
  }

  .show-filter-sec {
    .content-height {
      height: calc(100vh - 90px);
    }
  }

  .ag-theme-quartz-dark {
    >div {
      max-height: calc(100vh - 280px);
    }
  }

  .cus-inner-height {
    max-height: calc(100vh - 80px);
    overflow: auto;
  }

  .hd-left-wrapper {
    width: calc(100% - 360px);
    position: relative;
    background: $black-700;

    .H_ui {
      * {
        box-sizing: border-box;
      }

      .H_ib_close {
        font-size: .6em;
        position: absolute;
        right: 12px;
        width: 12px;
        height: 12px;
        cursor: pointer;
        top: 12px;
        z-index: 1;
        background: none;
        box-shadow: none;

        svg {
          fill: $white;
          position: static;
          right: 12px;
          top: 12px;
          width: 12px;
          height: 12px;
          cursor: pointer;
        }
      }
    }

    .H_ib_tail {
      &::before {
        bottom: 0;
      }

      &::after {
        bottom: 1px;
      }
    }

    .H_ib {
      &.H_ib_top {
        .H_ib_tail {
          &::after {
            border-color: $grey-900 transparent;
          }

          &::before {
            border-top-color: $green-100;
          }
        }
      }
    }

    .H_ib_body {
      font-family: "Inter", sans-serif;
      background: transparent;
      padding: 0;
      box-shadow: 0px 0px 11.21px 0px rgba(36, 202, 73, 0.30);
      width: 550px;
      position: relative;
    }

    .H_ib_tail {
      display: none;
    }

    .H_ib_content {
      margin: 0;
      min-width: 100%;
      font-family: "Inter", sans-serif;

      .tasks-modal {
        max-height: 220px;
        overflow: auto;
        max-width: 550px;

        &.map-driver-info {
          table {
            &.hw-table {
              tbody {
                tr {
                  cursor: auto;

                  &:hover {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }

      table {
        &.hw-table {
          width: 100%;

          thead {
            tr {
              th {
                padding: 8px;
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 8px;
                min-width: 80px;

                &:first-child {
                  max-width: 200px;
                  min-width: 150px;
                }

                &:last-child(2) {
                  max-width: 200px;
                  min-width: 150px;
                }
              }
            }
          }
        }
      }
    }
  }

  .hd-right-wrapper {
    width: 360px;
    border-left: 1px solid $black-700;
    background: $grey-900;
    margin-top: -40px;
    position: relative;

    .hd-drivers-listing {
      width: 100%;
      display: inline-block;

      .hd-drivers-search {
        padding: 20px;

        .search-field {
          fieldset {
            background: $black-800;
            border-color: $black-800 !important;
          }

          .MuiInputBase-formControl {
            background-color: $black-800;

            .MuiInputBase-input {
              background-color: $black-800;
              border-radius: 10rem;
            }
          }
        }
      }

      .hd-drivers-list-inner {
        width: 100%;
        display: inline-block;
        padding-right: 5px;
        height: calc(100vh - 140px);
        overflow: auto;

        ul {
          height: 100%;

          li {
            border-bottom: 1px solid $black-700;
            padding: 14px 20px;

            &:last-child {
              border-bottom: none;
            }

            .dot-duty {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: $green-100;

              &.dot-off {
                background-color: $red-300;
              }
            }

            .driver-duty-txt {
              color: $green-100;
            }

            .driver-off-duty-txt {
              color: $red-300;
            }
          }
        }
      }
    }

    .driver-details-drawer {
      position: absolute;
      right: 360px;
      top: 0;
      width: 450px;
      background: $black-800;
      height: calc(100vh - 50px);
      overflow: auto;
      z-index: 9;

      .hd-tabs {
        &.hd-drawer-tabs {
          padding: 0;
          background-color: transparent;
          border-bottom: 1px solid $black-300;

          .MuiTabs-root {
            button {
              padding: 16px 16px 15px;
            }
          }
        }
      }

      .driver-details-head {
        padding: 20px 16px;
      }

      .driver-info-tabs {
        padding: 16px;
        height: calc(100vh - 170px);
        overflow: auto;

        .driver-tasks-tab {
          svg {
            margin: 0;
          }
        }

        button {
          border-color: $black-400;
          padding: 0;
          min-height: 34px;
        }

        .driver-tabs-con {
          width: calc(100% - 20px);
        }

        .MuiPaper-root {
          border-radius: 0;
          box-shadow: none;
          background-color: transparent;

          hr {
            width: 100%;
            height: 2px;
            border-top: 1px solid $black-700;
            opacity: 1;
            margin: 0;
            background-image: none !important;
          }
        }

        svg {
          margin-top: 8px;
        }

        h5 {
          span {
            max-width: calc(100% - 30px);
          }
        }

        .text-24 {
          font-size: 24px !important;
        }
      }
    }
  }

  .cus-filter-sec {
    background-color: $black;

    .filter-min-w {
      width: 220px;
    }

    .filter-box {
      .MuiInputBase-root {
        background: $black-800;
        border-radius: 20px;
        min-height: 40px;

        .MuiSelect-select {
          color: $white;
          min-height: 40px;
          box-sizing: border-box;
        }
      }
    }

    .filter-pro-status {
      .MuiInputBase-formControl {
        .MuiInputBase-input {
          padding: 0.5rem 0.75rem;
          min-height: 40px;
          box-sizing: border-box;
        }
      }
    }

    .custom-search-field {
      width: 100%;
    }

    .filter-price-unit,
    .filter-brands,
    .filter-pro-status {
      fieldset {
        border: none !important;
      }

      .MuiFormControl-root {
        .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
          margin-top: -2px;
        }
      }
    }

    .filter-btns {
      button {
        &.filter-clear-all {
          font-size: 14px;
          color: $primary;
          text-decoration: none;
          padding: 5px;
          margin: 0;
          border-radius: 20px;
          text-transform: capitalize;
        }

        &.filter-apply {
          min-height: 40px;
          border-radius: 30px;
          text-transform: capitalize;
        }
      }
    }

    .dark-field {
      .react-datepicker-wrapper {
        input {
          min-height: 40px;
          font-size: 12px;
        }
      }
    }

  }

  .loader-multi-drawer {
    height: calc(100vh - 205px);
  }

  .no-data-found {
    p {
      color: $white;
    }
  }

  .multi-checkbox-sec {
    flex-direction: row;
  }

  &:has(.hd-right-wrapper .driver-details-drawer) {
    .hd-left-wrapper {
      filter: blur(7px);
    }
  }
}

.table-pg {
  .filter-pro-status {
    label {
      margin-top: -2px;
    }

    .MuiInputBase-root {
      background: $black-800;
      border-radius: 20px;
      min-height: 40px;

      .MuiSelect-select {
        color: $white;
        min-height: 40px;
        box-sizing: border-box;
      }
    }
  }
}

.hd-tabs {
  border-bottom: 1px solid $black-700;
  background: $grey-900;
  padding: 0 1rem;
  min-height: 48px;

  .MuiTabs-root {
    border-radius: 0;
    padding: 0;
    background-color: transparent;

    button {
      color: $black-400 !important;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      padding: 16px 20px 15px;
      width: auto;
      background: transparent;
      border-radius: 0;

      &.Mui-selected {
        color: $primary !important;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .MuiTabs-flexContainer {
      max-width: max-content;
    }

    .MuiTabs-indicator {
      box-shadow: none;
      background-color: transparent;
      border-radius: 0;
      border-bottom: 2px solid $primary;
    }
  }

  &.hd-sub-tabs {
    border: none;

    .MuiTabs-root {
      .MuiTabs-flexContainer {
        border-radius: 9px;
        background: $black-700;
        padding: 5px;

        button {
          color: $white-700 !important;
          font-size: 13px;
          padding: 10px 16px;

          &.active {
            border-radius: 7px;
            background: $primary;
          }
        }
      }
    }
  }

  &.hd-check-tabs {
    border: none;
    background: transparent;
  }
}

header {
  &.header-sec {
    .hd-tabs {
      border: none;
      background: transparent;
      padding: 0;
      flex: none !important;
      min-height: auto;

      .MuiTabs-root {
        .MuiTabs-indicator {
          border: none;
        }

        button {
          font-size: 14px;
          padding: 5px 15px;
        }
      }
    }

    .user-sec {
      box-shadow: none;

      .user-first-chr {
        color: $white;
      }

      .user-name {
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
  }
}

.time-task-driver {
  border-bottom: 1px solid $black-700;
  background: $grey-900;
  min-height: 40px;

  .select-rep-sec {
    border-right: 1px solid $black-700;

    .select-calendar {
      padding: 0.5rem 16px;
      position: relative;

      p {
        color: $black-400;
        font-size: 14px;
        margin: 0 10px;

        svg {
          margin-right: 5px;
        }
      }

      .dark-field {
        cursor: pointer;
      }

      .react-datepicker__input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .react-datepicker__calendar-icon {
          right: 0;
          padding: 0.5rem 1rem;
          box-sizing: border-box;
        }

        .dropdown-arrow-order {
          order: 2;
        }

        .dark-field {
          padding: 0 20px 0 15px;
          margin: 0;
        }
      }

    }

    .select-with-radio {
      p {
        color: $black-400;
        font-size: 14px;
        margin-right: 10px;
        text-transform: capitalize;
      }

      button {
        border-radius: 0;
        padding: 0.5rem 1.2rem;
      }

      .radio-colors {
        margin-right: 10px;

        span {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        .driver-off-duty {
          background-color: $red-300;
        }

        .driver-idle {
          background-color: $primary;
        }

        .driver-in-transit {
          background-color: $orange-100;
        }
      }

      .radio-task-colors {
        margin-right: 10px;

        span {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 5px solid $white;
          background-color: $white;
          position: relative;

          &::after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: -13px;
            left: 50%;
            transform: translateX(-50%);
            border: 4px solid transparent;
            border-top: 6px solid $white;
          }
        }

        .task-unassigned {
          background-color: $grey-400;
          border-color: $grey-400;

          &::after {
            border-top: 6px solid $grey-400;
          }
        }

        .task-assigned {
          background-color: $yellow-100;
          border-color: $yellow-100;

          &::after {
            border-top: 6px solid $yellow-100;
          }
        }

        .task-intransit {
          background-color: $purple-200;
          border-color: $purple-200;

          &::after {
            border-top: 6px solid $purple-200;
          }
        }

        .task-succeeded {
          background-color: $green-100;
          border-color: $green-100;

          &::after {
            border-top: 6px solid $green-100;
          }
        }

        .task-failed {
          background-color: $red-100;
          border-color: $red-100;

          &::after {
            border-top: 6px solid $red-100;
          }
        }
      }
    }
  }
}

body {
  .MuiPopover-root {
    .MuiPaper-root {
      &:has(.hd-dropdown) {
        padding: 0;
        border-radius: 5px;
        margin-top: 5px;
        background: $grey-900 !important;
        width: 180px;
      }

      .hd-dropdown {
        padding: 10px;

        .radio-colors {
          .duty-color {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 6px;
            border-radius: 50%;
          }

          .driver-off-duty {
            background-color: $red-300;
          }

          .driver-idle {
            background-color: $primary;
          }

          .driver-in-transit {
            background-color: $orange-100;
          }

          .task-color {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 5px solid $white;
            background-color: $white;
            position: relative;
            top: -2px;
            display: inline-block;
            margin-right: 6px;

            &::after {
              content: "";
              width: 0;
              height: 0;
              position: absolute;
              bottom: -13px;
              left: 50%;
              transform: translateX(-50%);
              border: 4px solid transparent;
              border-top: 6px solid $white;
            }

            &.task-unassigned {
              background-color: $grey-400;
              border-color: $grey-400;

              &::after {
                border-top: 6px solid $grey-400;
              }
            }

            &.task-assigned {
              background-color: $yellow-100;
              border-color: $yellow-100;

              &::after {
                border-top: 6px solid $yellow-100;
              }
            }

            &.task-intransit {
              background-color: $purple-200;
              border-color: $purple-200;

              &::after {
                border-top: 6px solid $purple-200;
              }
            }

            &.task-succeeded {
              background-color: $green-100;
              border-color: $green-100;

              &::after {
                border-top: 6px solid $green-100;
              }
            }

            &.task-failed {
              background-color: $red-100;
              border-color: $red-100;

              &::after {
                border-top: 6px solid $red-100;
              }
            }
          }
        }

        .MuiFormControl-root {
          width: 100%;

          label {
            margin: 0;

            .MuiTypography-root {
              color: $white-700;
              font-weight: 400;
            }

            svg {
              border-color: $white-600;
              width: 1rem;
              height: 1rem;
              border-radius: 4px;
            }
          }
        }
      }

      &:has(.hd-dropdown-sec) {
        padding: 0;
        border-radius: 5px;
        margin-top: 5px;
        background: $grey-900 !important;
        min-width: 180px;

        li {
          padding: 0.5rem 1rem;

          &:hover {
            background: linear-gradient(0deg, rgba(1, 99, 255, 0.40) 0%, rgba(1, 99, 255, 0.40) 100%), #2C2C35;
          }
        }

        p {
          font-size: 14px;
          color: $white-700;
        }

        .popover-dropdown {
          .MuiTypography-root {
            font-size: 14px;
            color: $white-700;
          }

          &:hover {

            .material-icons-round,
            * {
              color: $white-700;
            }
          }
        }
      }

      &:has(.hd-dropdown-sec.user-dropdown) {
        margin-top: -5px;
        min-width: 100px;
      }

      &:has(.reassign-driver-dropdown) {
        border-radius: 12px;
        border: 1px solid $primary;
        width: 187px;
      }

      &:has(.hd-filter-dropdown-sec) {
        width: 300px;
        border-radius: 12px;
        background: $black-800 !important;
        overflow: visible;
      }

      .hd-filter-dropdown-sec {
        padding: 20px;

        .react-datepicker-wrapper {
          .react-datepicker__close-icon {
            &:after {
              background-color: transparent;
            }
          }
        }

        .dark-field {
          .MuiInputBase-formControl {
            background-color: transparent !important;

            .MuiInputBase-input {
              background-color: transparent;
            }
          }

          fieldset {
            border-color: $black-50 !important;
          }

          .react-datepicker-wrapper {
            input {
              min-width: auto;
              border: 1px solid $black-50;
            }
          }
        }

        .MuiFormControl-root {
          label {
            margin-top: -2px;
          }

          .form-select {
            height: 40px;
          }
        }

        .MuiInputBase-formControl {
          .MuiInputBase-input {
            height: 40px;
            box-sizing: border-box;
          }
        }

        .filter-btns {
          button {
            min-height: 40px;
            text-transform: none;
            box-shadow: none;

            &.MuiButton-textSecondary {
              border: 1px solid $black-200;
            }
          }
        }
      }
    }
  }

  .cus-drawer {
    p {
      white-space: normal;
    }

    .drawer-head-sec {
      min-height: 60px;
    }

    .drawer-scroll-sec {
      height: calc(100vh - 140px);
      overflow: auto;

      &.drawer-scroll-without-footer {
        height: calc(100vh - 60px);
      }

      .drawer-listing {
        button {
          border-radius: 20px;
          border: 1px solid $primary;
          height: 35px;
          display: flex;
          flex-wrap: wrap;
          padding: 0 20px;
          min-height: auto;
        }
      }
    }

    .drawer-bottom-btns {
      min-height: 80px;

      button {
        font-size: 14px;
        padding: 12px 6px;
        flex: 1;
        border-radius: 30px;
        text-transform: capitalize;

        &.MuiButton-outlinedSecondary {
          color: $white;
          border-color: $black-800;
        }

        &.purple-btn {
          background: #8E0CF5B2;
        }

        &.green-btn {
          background: #24CA4999;

          &:hover,
          &:focus,
          &:focus:not(:hover) {
            box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgb(0 151 34 / 20%), 0rem 0.25rem 1.4375rem 0rem rgb(0 109 24 / 30%), 0rem 0.5rem 0.625rem -0.3125rem rgb(0 96 21 / 30%);
          }
        }
      }
    }

    &.task-details-drawer {

      .ag-theme-quartz-dark {
        margin: 16px;

        .ag-grid--table {
          .ag-header {
            background-color: $black-700;

            .ag-header-cell {
              &.ag-column-last {
                div {
                  justify-content: start;
                }
              }
            }
          }

          .ag-row {
            .ag-cell {
              background-color: $black-25;
            }
          }
        }
      }

      .ag-grid--table {
        .ag-overlay-loading-wrapper {
          background: rgba(0, 0, 0, 0.8);
        }

        .ag-icon {
          color: $black-400;
        }

        .ag-icon-asc {
          &::before {
            color: $black-400;
          }
        }
      }

      .MuiModal-backdrop {
        background: rgba(0, 0, 0, 0.40);
        backdrop-filter: blur(7px);
      }

      .MuiDrawer-paper {
        padding: 0;
        margin: 0;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
        background: $black-900;
        box-shadow: none;
        height: 100vh;
        top: 0;
        width: 480px !important;
      }
    }

    .drawer-radio-sec,
    .drawer-checkbox-sec {
      .MuiFormGroup-root {
        margin-left: 3px;
      }

      label {
        .MuiTypography-root {
          color: $black-600;
          font-size: 1rem;
          font-weight: normal;
        }
      }
    }

    .drawer-date-field {
      .MuiFormControl-root {
        width: 100%;

        .MuiInputBase-formControl {
          input {
            text-transform: uppercase;
          }

          ::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
        }
      }

      .error_class {
        font-size: 12px;
        margin-top: 5px;
        padding-left: 14px;
        color: $red-100;
        font-weight: 300;
        width: 100%;
        word-break: break-all;
        white-space: normal;
        line-height: normal;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .Mui-error {
      color: $red-100 !important;
    }

    .reassign-driver-btn {
      button {
        border-radius: 20px;
        border: 1px solid $primary;
        height: 35px;
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        min-height: auto;
        text-transform: none;
      }
    }

    .cus-wrapper-drawer {
      .drawer-date-field {
        .react-datepicker-wrapper {
          .react-datepicker__view-calendar-icon {
            svg {
              display: none;
            }

            input {
              background-color: $black-800;
              color: $white;
              border-radius: 20px;
              border: none;
              min-height: 44px;
              font-size: 14px;
              min-width: auto;
            }
          }
        }
      }

      .button-error {
        position: absolute;
        right: 0;
        bottom: -8px;
        color: $red-100;
        font-size: 12px;
        text-align: center;
        width: 100%;

        &.text-left {
          bottom: -23px;
        }
      }
    }

    &.multi-drawer {
      .MuiDrawer-paper {
        width: auto !important;

        .cus-wrapper-drawer {
          width: 480px;
        }

        .next-drawer {
          position: fixed;
          right: 480px;
          top: 0;
          width: 420px;
          background: $black-800;
          min-height: 100vh;

          &.activity-log-drawer {
            width: 550px;

            table {
              &.hw-table {
                width: 100%;
              }
            }
          }

          .drawer-head {
            min-height: 60px;
            box-sizing: border-box;
          }

          .street-map-view {
            img {
              width: 100%;
              border-radius: 6px;
              overflow: hidden;
            }
          }

          .drawer-scroll-sec {
            height: calc(100vh - 60px);
          }
        }
      }
    }

    .hd-drivers-listing {
      width: 100%;
      display: inline-block;
      padding: 0;

      ul {
        height: 100%;

        li {
          border-bottom: 1px solid $black-700;
          padding: 14px 16px;

          .radio-select {
            svg {
              width: 1rem;
              height: 1rem;
            }
          }

          &:last-child {
            border-bottom: none;
          }

          .dot-duty {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $green-100;

            &.dot-off {
              background-color: $red-300;
            }
          }

          .driver-duty-txt {
            color: $green-100;
          }

          .driver-off-duty-txt {
            color: $red-300;
          }
        }
      }
    }

    &.driver-list-drawer {
      .drawer-scroll-sec {
        height: calc(100vh - 225px);
      }

      .hd-drivers-listing {
        ul {
          li {
            border-bottom: 1px solid $grey-100;
          }
        }
      }

      .search-field {
        .MuiInputBase-formControl {
          .MuiInputBase-input {
            padding-left: 50px;
            background-color: $grey-200;
          }
        }
      }

      .drawer-foot {
        background: $grey-200;
      }

      .drawer-dot-duty {
        color: $green-100;
        font-size: 12px;

        &:before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $green-100;
        }

        &.drawer-dot-off {
          color: $red-300;

          &:before {
            background-color: $red-300;
          }
        }
      }
    }

    &.check-in-out-drawer {
      .drawer-scroll-sec {
        height: calc(100vh - 160px);
      }
    }

    .no-data-found {
      p {
        color: $white;
      }
    }

    .region-listing {
      border-bottom: 1px solid $black-700;
      padding: 12px 16px 20px;
      width: calc(100% + 32px);
      margin-left: -16px;

      &:last-child {
        border-bottom: none;
      }

      .region-txt {
        width: 100%;
      }

      .hd-google-suggestions {
        width: 100%;
      }

      p {
        word-break: break-all;
        line-height: normal;
      }

      textarea {
        line-height: normal;
        max-width: calc(100% - 65px);
      }

      ::-webkit-scrollbar {
        display: none;
      }

      .MuiInputAdornment-root {
        button {
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

  }

  .cus-popover {
    .MuiPaper-root {
      padding: 0;
      border-radius: 5px;
      margin-top: 5px;
      background: $grey-900 !important;

      ul {
        padding: 10px;

        li {
          background-color: transparent;

          &:hover {
            background: linear-gradient(0deg, rgba(1, 99, 255, 0.4) 0%, rgba(1, 99, 255, 0.4) 100%), #2C2C35;
          }

          &.dropdown-reset {
            justify-content: center;
            border-top: 1px solid $black-800;
            border-radius: 0;
            margin-top: 10px;
            padding: 5px 0;
            background: transparent;
            cursor: auto;

            button {
              width: 100%;
              padding: 10px 5px 5px 5px;
              min-height: auto;
              text-transform: capitalize;
              color: $red-100;
              font-size: 14px;
              font-weight: normal;
            }
          }
        }
      }
    }

    &.status-popover {
      .MuiPaper-root {
        ul {
          li {
            padding: 10px;

            &:hover {
              background: $black-800;
            }

            .MuiTypography-root {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// .cus-badge {
//   border-radius: 5px;
//   padding: 3px 8px;
// }

button {
  &.grey-btn {
    color: $white;
    background: $black-700;

    &:hover,
    &:focus,
    &:focus:not(:hover) {
      color: $white;
      background: $black-700;
      box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgba(44, 44, 53, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(44, 44, 53, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(44, 44, 53, 0.2);
    }
  }

  &.yellow-btn {
    color: $black;
    border-radius: 30px;
    background-color: $yellow-100;

    &:hover,
    &:focus,
    &:focus:not(:hover) {
      color: $black;
      background-color: $yellow-100;
      box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgba(246, 233, 116, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(249, 241, 88, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(244, 232, 100, 0.2);
    }
  }
}

.main-content-area {
  min-height: calc(100vh - 50px);
  margin-top: 50px;

  &:has(.main-wrapper) {
    height: calc(100vh - 68px);
    background-color: $black-800;
  }

  &.dark-mode {
    header {
      background-color: $black !important;

      &.header-sec {
        .user-sec {
          .user-first-chr {
            color: $black;
          }
        }
      }

      button {
        border-radius: 10rem;
        text-transform: capitalize;
        box-shadow: none;

        &.grey-btn {
          min-width: 150px;
        }
      }
    }

    button {
      border-radius: 10rem;
      text-transform: none !important;

      &.grey-btn {
        color: $black-400;
        background: $black-700;

        &:hover,
        &:focus,
        &:focus:not(:hover) {
          box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgba(44, 44, 53, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(44, 44, 53, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(44, 44, 53, 0.2);
        }
      }
    }

    .ag-grid--table {
      .ag-overlay-loading-wrapper {
        background: rgba(0, 0, 0, 0.8);
      }

      .ag-icon {
        color: $black-400;
      }

      .ag-icon-asc {
        &::before {
          color: $black-400;
        }
      }
    }

    .notifiction-dawer {
      .icon-wrapper {
        svg {
          fill: rgb(154, 154, 175);
        }
      }
    }
  }
}

.main-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .heading-settings {
    padding: 16px;
    border-bottom: 1px solid $black-200;
  }
}

.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  max-height: calc(100vh - 120px);
  overflow: auto;

  .sidebar-sec {
    width: 300px;
    height: 100%;
    background: $black-700;

    .settings-sidebar {
      height: 100%;

      ul {
        padding: 30px 16px;

        li {
          padding: 17px 20px;
          cursor: pointer;

          svg,
          path {
            color: $black-400;
            fill: $black-400;
          }

          .menu-name {
            color: $black-400;
          }

          &.active {
            border-radius: 8px;
            background: $primary;

            .menu-name {
              color: $white-700;
            }

            svg,
            path {
              color: $white-700;
              fill: $white-700;
            }
          }
        }
      }
    }

    .MuiPaper-root {
      box-shadow: none;
      height: 100%;
      background: transparent;
      padding: 0;
    }
  }

  .contents-right {
    width: calc(100% - 300px);
    height: 100%;
    overflow: auto;

    h5 {
      font-size: 20px;
    }

    .MuiPaper-root {
      height: 100%;
      box-shadow: none;
      border-radius: 0;
      background: transparent;
      padding: 16px;
    }

    .MuiFormControl-root {
      label {
        &.Mui-focused {
          color: $white;
        }
      }

      .MuiInputBase-root,
      .MuiInputBase-root input {
        color: $white;
        background-color: $grey-900 !important;

        fieldset {
          border-color: transparent !important;
        }
      }
    }

    button {
      &.MuiButton-outlined {
        &.dark-outlined-btn {
          color: $black-200;
          border-color: $black-200;
        }
      }
    }

  }
}

.settings-pg-contents {
  .MuiFormControl-root {
    .MuiInputBase-root {
      border-radius: 30px;

      input {
        border-radius: 30px;
      }
    }
  }

  table {
    &.hw-table {
      margin-bottom: 16px;
    }
  }
}

.custom-search-field {
  &.dark-field {
    .MuiInputBase-formControl {
      min-width: 270px;

      input {
        min-height: 40px;
        box-sizing: border-box;
      }
    }
  }
}

.dark-field {
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  input::-webkit-input-placeholder {
    color: $black-200;
  }

  input::-moz-placeholder {
    color: $black-200;
  }

  input:-ms-input-placeholder {
    color: $black-200;
  }

  input:-moz-placeholder {
    color: $black-200;
  }

  .MuiIconButton-root {
    svg {
      color: $black-200;
    }
  }

  label {
    color: $black-200;
  }

  fieldset {
    border-color: $black-800 !important;
  }

  .MuiInputBase-formControl {
    background-color: $black-800 !important;
    border-radius: 20px;

    .MuiInputBase-input {
      background-color: $black-800;
      color: $white;
      border-radius: 20px;
    }

    &.Mui-disabled {
      .MuiInputBase-input {
        background-color: $black-800;
      }
    }
  }

  label {
    &.Mui-disabled {
      color: $black-300;
    }
  }

  .react-datepicker-wrapper {
    input {
      color: $white;
      background-color: $black-800;
      border: none;
      min-height: 40px;
      font-size: 14px;
      min-width: 240px;
    }

    .react-datepicker__close-icon {
      &:after {
        background-color: transparent;
      }
    }
  }

}

.hd-date-field {
  .MuiFormControl-root {
    .MuiInputBase-formControl {
      input {
        text-transform: uppercase;
      }
    }
  }
}

table {
  &.hw-table {
    background-color: $grey-900;
    border-radius: 8px;
    overflow: hidden;

    thead {
      background: $black-700;

      tr {
        th {
          padding: 12px 20px;
          line-height: normal;
          text-align: left;
        }
      }
    }

    tbody {
      background-color: $grey-900;

      tr {
        td {
          padding: 20px;
          line-height: normal;
          border-bottom: 1px solid $black-700;
          text-align: left;
        }

        &:last-child {
          td {
            border: none;
          }
        }
      }
    }

    .table-action-btn {
      button {
        background-color: $grey-900;
        box-shadow: none;
      }
    }
  }
}

.tasks-modal {
  width: 100%;
  max-width: 600px;
  border: 1px solid $green-100;
  border-radius: 8px;
  background: $grey-900;

  h6 {
    padding: 12px 20px;
  }

  table {
    &.hw-table {
      border-radius: 0 0 8px 8px;

      tbody {
        tr {
          cursor: pointer;

          &:hover {
            background: #040405;
          }
        }
      }
    }
  }
}

.task-dot {
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.successfull {
    background-color: #00FFF0;
  }

  &.failed {
    background-color: #4807EA;
  }

  &.on-time {
    background-color: #17E2B5;
  }

  &.delayed-10 {
    background-color: #6655EA;
  }

  &.delayed-10-60 {
    background-color: #FE7E07;
  }

  &.delayed-60 {
    background-color: #E80FE9;
  }

  &.drop-off {
    background-color: #F471FF;
  }

  &.picked-up {
    background-color: #FED366;
  }
}

.group-by {
  button {
    border-radius: 6px;
    min-width: 90px;
    min-height: 30px;
    text-transform: capitalize;

    &:hover {
      box-shadow: 0rem .9rem .1rem -0.2rem rgb(44 44 53 / 6%), 0rem 0.2rem 1.1rem 0rem rgb(74 73 73 / 7%), 0rem 0.2rem 0.1rem -0.1rem rgb(44 44 53 / 0%);
    }
  }
}

.report-bar {
  .MuiSelect-select {
    &.MuiSelect-outlined {
      height: 40px;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      box-sizing: border-box;
    }
  }

  .dark-field {
    .MuiInputBase-formControl {
      height: 40px;
    }
  }

  .MuiFormControl-root {
    label {
      margin-top: -2px;
    }
  }
}

.row-pointer,
.row-cursor {
  .ag-body {
    .ag-center-cols-viewport {
      .ag-row {
        cursor: pointer;
      }
    }
  }
}

.hd-search-filter {
  .custom-search-field {
    .MuiInputBase-formControl {
      height: 40px;

      input {
        height: 40px;
        box-sizing: border-box;
      }
    }
  }

  button {
    &.hd-filter-dropdown {
      border-color: $black-200;
      border-radius: 30px;
      color: $black-600;
      text-transform: capitalize;
      width: 120px;
      height: 40px;
      font-size: 14px;

      svg {
        font-size: 2rem !important;
        color: $primary;
        -ms-transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
      }

      &.active-filter {
        svg {
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
}

.duty-color {
  .dot-duty {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.on-duty {
      background-color: $green-100;
    }

    &.off-duty {
      background-color: $red-300;
    }
  }
}

.cus-modal {
  &.bg-black {
    .MuiModal-backdrop {
      background: rgba(0, 0, 0, 0.40);
      backdrop-filter: blur(5px);
    }

    .MuiDialog-container {
      .MuiDialog-paper {
        background: $black-800;
        box-shadow: 0px 0px 11px 0px rgba(36, 202, 73, 0.30);

        h2 {
          color: $white;
          padding: 1.875rem;

          svg {
            color: $white;
          }
        }

        .modal-close {
          color: $white;
          position: absolute;
          right: 22px;
          top: 22px;
        }

        .MuiDialogContent-root {
          padding: 1.875rem;
          padding-top: 0;
        }
      }
    }

    &.task-route-modal {
      .MuiDialog-container {
        .MuiDialog-paper {
          max-width: 586px;
        }
      }
    }

    .MuiDialogActions-root {
      button {
        border-radius: 30px;
        box-shadow: none;
        text-transform: capitalize;
        font-size: 14px;
        min-width: 130px;
        min-height: 38px;
      }
    }

  }
}

.upload-id-sec {
  justify-content: center;
  gap: 40px;

  .MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .upload-wrapper {
      border-radius: 20px;
      background: #F2F3F7;
      display: flex;
      width: 134px;
      height: 134px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
    }
  }
}

.yellow-btn {
  button {
    color: $black;
    border-radius: 30px;
    background-color: $yellow-100;

    &:hover,
    &:focus,
    &:focus:not(:hover) {
      color: $black;
      background-color: $yellow-100;
      box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgba(246, 233, 116, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(249, 241, 88, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(244, 232, 100, 0.2);
    }
  }
}

.select-filter-sec {
  button {
    border-radius: 30px;
    background: $black-800;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $black-800;
    }
  }
}

.hd-calendar-main {
  position: absolute;
  left: 05px;
  top: 45px;
  z-index: 1;

  .rdrCalendarWrapper {
    background: $grey-900;
    border-radius: 5px;
    color: $white;
    overflow: hidden;

    .rdrDateDisplayWrapper {
      background-color: $grey-900;
      border-bottom: 1px solid $black-700;

      .rdrDateDisplayItem {
        background-color: transparent;
        box-shadow: none;

        input {
          color: $white;
        }
      }
    }

    .rdrMonthAndYearWrapper {
      .rdrMonthAndYearPickers {
        select {
          color: $white;
        }
      }
    }
  }

  .rdrDayNumber {
    span {
      color: $white;
    }
  }
}

.hd-google-suggestions {
  position: relative;

  .Mui-error {
    margin-top: -10px;
    padding-left: 14px;
  }

  .autocomplete-dropdown-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    z-index: 22;
    border-radius: 5px;
    margin: 0;
    background: $grey-900;
    max-height: 250px;
    overflow: auto;

    >div,
    .suggestion-item {
      background-color: transparent !important;
      color: $white-600;
      font-size: 14px;
      padding: 10px 18px;
      border-bottom: 1px solid $black-700;
      line-height: normal;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: $black-700 !important;

        &:first-child {
          border-radius: 5px 5px 0 0;
        }

        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }

      span {
        font-size: 14px;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
}

.relativeDifferenceDark {
  color: $red-100;
}

.skeleton {
  .MuiSkeleton-pulse {
    background-color: #ffffff65 !important;
  }
}

.dark-calendar {
  .cal-end {
    .react-datepicker-popper {
      margin-left: -15px;
    }
  }

  .react-datepicker-popper {
    margin-top: 5px;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      background-color: $grey-900;
      border-color: $black-700;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .react-datepicker {
      color: $black-500;
      background: $grey-900;
      border: none;
      box-shadow: 0rem 0.5rem 0.25rem -0.5rem rgb(5 5 5), 0rem 0.2rem 0.5rem 0rem rgb(0 0 0);
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: $black-500;

      &.react-datepicker__day--disabled {
        color: $grey-800;
      }
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      background-color: $primary;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: $black-500;
    }

    .react-datepicker__input-time-container {
      .react-datepicker-time__input-container {
        .react-datepicker-time__input {
          input {
            background-color: transparent;
            border: 1px solid $black-700;
            padding: 2px;
            border-radius: 4px;
            color: $black-500;

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }
  }

  .error_class {
    font-size: 12px;
    margin-top: 5px;
    padding-left: 14px;
    color: $red-100;
    font-weight: 300;
    width: 100%;
    word-break: break-all;
    white-space: normal;
    line-height: normal;
    display: flex;
    flex-wrap: wrap;
    max-width: 240px;
    position: absolute;
    top: 100%;

    &.rem-pos {
      position: static;
      max-width: 100%;
    }
  }
}

#mapContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.timeline-sec {
  ul {
    li {
      &::before {
        content: none;
      }

      &:last-child {
        .MuiTimelineConnector-root {
          display: none;
        }
      }

      .MuiTimelineConnector-root {
        background-color: $black-300;
        width: 1px;
        margin-left: -1px;
      }

      .MuiTypography-root {
        padding-top: 0;
      }
    }
  }
}

.select-all-field {
  label {
    margin: 0;
    min-height: auto;
    display: flex;
    align-items: center;

    span {
      padding: 0;

      svg {
        width: 1rem !important;
        height: 1rem !important;
      }
    }

    .MuiTypography-root {
      margin-left: 8px;
      font-weight: normal;
    }
  }
}

.driver-details-sec {
  button {
    &.green-btn {
      background: $green-100;
      min-height: auto;
      height: 40px;
      opacity: 1 !important;

      &:hover,
      &:focus,
      &:focus:not(:hover) {
        background: $green-100;
        box-shadow: 0rem 0.875rem 1.625rem -0.75rem rgb(0 151 34 / 20%), 0rem 0.25rem 1.4375rem 0rem rgb(0 109 24 / 30%), 0rem 0.5rem 0.625rem -0.3125rem rgb(0 96 21 / 30%);
      }
    }
  }

  .dot-duty {
    color: $green-100;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $green-100;
    }

    &.dot-off {
      color: $red-300;

      &:before {
        background-color: $red-300;
      }
    }
  }
}

.select-pr {
  .form-select {
    padding-right: 40px;
  }
}

.MuiDialog-root {
  .MuiDialog-container {
    .MuiPickersLayout-root {
      .MuiTimeClock-arrowSwitcher {
        button {
          background: transparent;
        }
      }
    }
  }
}

.select-pr {
  .form-select {
    padding-right: 40px;
  }
}

.count-num {
  position: absolute;
  right: 1px;
  top: 7px;
  background-color: $primary;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
}

.create-driver {
  .select-pr {
    .form-select {
      padding-right: 0;
    }
  }
}

body:has(.hd-tooltip) {
  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      background: $black-700;
      font-size: 0.7rem;

      .MuiTooltip-arrow {
        color: $black-700;
      }
    }
  }
}

.driver-listing-rep {
  h6 {
    span {
      max-width: calc(100% - 33px);
    }

    svg {
      width: 25px;
    }
  }
}

.badge-tag {
  border-radius: 23px;
  background: rgba(253, 68, 56, 0.08);
  padding: 4px 10px;
  color: $red-100;

  &.on-time {
    color: $green-100;
    background: #d5ffeb42;

    svg,
    path {
      color: $green-100;
      fill: $green-100;
    }
  }
}

.headquarters-address-field {
  .dark-field {
    .MuiInputBase-formControl {
      .MuiInputAdornment-root {
        display: none;
      }
    }
  }
}

.delivery-speed-field {
  .MuiFormControl-root {
    height: 40px;

    label {
      margin-top: -2px;
    }

    .form-select {
      height: 100%;
    }
  }
}

/* Hyper Drive Css Ends */

/* Cannabinoids Pages CSS Start */
.cannabinoids-tabs {
  .MuiStack-root {
    width: auto;
    max-width: max-content;
    border-radius: 10rem;
    padding: 5px;
    margin-bottom: -15px;
    background-color: $white;

    button {
      color: $grey-800;
      font-size: 14px;
      border: none;

      &.active {
        background-color: $grey-200;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  &.batch-logs-tabs {
    position: absolute;
    left: 50%;
    top: 22px;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.select-arrow {
  svg {
    display: block;
  }
}

.grid-list-view {
  .gl-view {
    background-color: $white;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
    }
  }
}

.ellipsis-three {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Cannabinoids Pages CSS Ends */


/* ################ */

/* #### Badges #### */
.cus-badge {
  badge {
    border-radius: 140px;
    background: transparent;
    padding: 3px 9px;
    gap: 10px;
    font-size: 11px;
    line-height: 13px;

    &.new-badge {
      background: linear-gradient(283deg, #137E59 -41.77%, #0CDB93 134.04%);
    }

    &.vip-badge {
      gap: 5px;
      background: radial-gradient(974.12% 319.84% at 198.65% 133.33%, #F57F27 0%, #FCCF48 85.13%, #FCCF48 100%);
    }

    &.birthday-badge {
      padding: 0;
    }
  }
}

/* ################ */


/* Sort By Filter */
.sort-by-filter {
  .MuiMenu-paper {
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 240px;

      li {
        width: 50%;
        min-width: auto;
        padding: 4px 6px;
        border-radius: 0;

        &.date-filter-container {

          &:hover,
          &:focus {
            background-color: transparent;
          }

          .filter-inner-container {
            width: 100%;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .filter-date-range {
          min-width: 100% !important;

          .react-datepicker-popper {
            position: fixed !important;
          }
        }
      }

      &:has(.filter-inner-container) {
        li {
          &.date-filter-container {
            width: 100%;
          }
        }
      }
    }
  }
}

/* ################ */


/* Sale Price Discout Section */
.sale-field-sec {
  .code-inner-field {
    width: 100%;
    margin-bottom: 15px;
    position: relative;

    .MuiFormControl-root {
      input {
        height: 1.625em;
        padding-right: 120px;
        color: #000000;
        font-weight: 500;
        background: transparent;
        border-radius: 10px;
      }

      fieldset {
        border-radius: 40px;
        border: 1px solid #9A9AAF;
      }
    }

    .dolar-percentage-btns {
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 16px;
      border-radius: 0;
      box-shadow: none;

      button {
        position: static;
        background-color: #F2F3F7;
        color: #2E2E3A;
        padding: 7px 10px;
        min-height: auto;
        font-size: 14px;
        width: 48px;
        border: none;

        &.active {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}

/* ################ */


/* Refund Refund Modal */
.refund-process-modal {
  .MuiDialog-paper {
    max-width: 550px;

    .MuiDialogTitle-root,
    .MuiDialogContent-root {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .MuiDialogContent-root {
      padding-top: 1rem;
      padding-bottom: 0;

      // .select-refound-label {
      //   .MuiInputLabel-shrink ~.MuiInputBase-root .MuiOutlinedInput-notchedOutline legend {
      //     font-size: 1.2em;
      //   }
      // }
      fieldset {
        border-radius: 10px;
      }

      .modal-radio {
        gap: 15px;

        label {
          margin: 0;
          flex: 1;
          border-radius: 10px;
          border: 1px solid $grey-50;
          display: flex;
          justify-content: center;
          padding: 0.157rem;

          span {
            color: $grey-500;

            .MuiSvgIcon-root {
              border-color: $grey-400;
            }
          }

          &:has(.Mui-checked) {
            border-color: $primary;
          }

          .Mui-checked {
            span {
              .MuiSvgIcon-root {
                border-color: $primary;
              }
            }
          }
        }
      }

      .form-input,
      .form-input .MuiInputBase-root,
      .form-input .MuiInputBase-root input {
        border-radius: 10px !important;
      }
    }

    .MuiDialogActions-root {
      padding: 1.5rem;

      button {
        font-size: 16px;
      }
    }
  }
}

body {
  .refund-popover {
    ul {
      li {
        &.Mui-selected {
          padding-left: 38px;

          &:after {
            content: "";
            display: inline-block;
            transform: rotate(45deg);
            height: 15px;
            width: 7px;
            border-bottom: 3px solid $primary;
            border-right: 3px solid $primary;
            position: absolute;
            left: 1.2rem;
            top: 8px;
          }
        }

        &.other-reason {
          color: $primary;
          font-weight: bold;
        }
      }
    }
  }
}

/* ################ */


.customToggle {
  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }
}

.order-calendar-filter {
  button {
    color: $grey-800;
    padding: 0.75rem 1.25rem;
    background-color: $white !important;
    border-radius: 10rem;
    min-width: 260px;
  }
}


/* Legal and FAQ sections UI */
.faq-sec-rep {
  padding: 20px;
  background: $white-600;
  border-radius: 16px;
  position: relative;

  .remove-btn {
    position: absolute;
    right: 15px;
    top: 8px;
  }

  .quill {
    &.quill-text-editor {
      background: $white;
    }
  }
}

/* ################ */

.hold_quantity {
  color: $primary;
  cursor: pointer;
}

.notes-modal-body {
  th {
    background: $grey-300;
  }

  td {
    padding: 10px 16px;
    white-space: nowrap;
  }
}

// Add Trait Banner Moda CSS
.add-trait-banner {
  .MuiDialog-paperFullWidth {
    max-width: 575px;
    .MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .trait-image-upload {
      .MuiBox-root {
        margin: 0 auto;
      }
    }
  }
}

// About, Team, Career pages Start
body {
  a {
    &.cta-link-btn {
      color: $grey-800;
      text-decoration: underline !important;
    }
  }

  .multi-select-platform {
    .MuiInputBase-root {
      .MuiSelect-select {
        .MuiBox-root {
          .MuiChip-root {
            .MuiChip-label {
              &.MuiChip-labelMedium {
                min-width: auto;
                padding: 2px 10px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .MuiInputLabel-shrink~.MuiInputBase-root {
      .MuiOutlinedInput-notchedOutline {
        legend {
          font-size: 2.7em;
        }
      }
    }
  }

}

.cta-btn {
  width: 100%;
  display: flex;
  gap: 20px;
  max-width: 700px;

  input {
    background: $grey-100;
  }

  fieldset {
    border-color: $grey-100;
  }
}

.select-static-pages {
  width: 100%;
  display: inline-block;
  max-width: 300px;

  .MuiSelect-select {
    background-color: $grey-100;
  }

  fieldset {
    border-color: $grey-100;
  }

  .MuiInputLabel-shrink~.MuiInputBase-root {
    .MuiOutlinedInput-notchedOutline {
      legend {
        font-size: 1em;
      }
    }
  }
}

.tab {
  display: inline-block;
  padding: 2px 10px;
  margin: 2px;
  background-color: $grey-200;
  color: $white;
  border-radius: 13px;
  font-size: 12px;
  color: $black;
  text-align: center;
  width: auto;
  text-transform: capitalize;
}

.add-change-image {
  width: 260px;
  height: 260px;
  object-fit: cover;
  border-radius: 20px;
  background: $grey-25;

  .MuiButtonBase-root {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
    border: none !important;
    box-shadow: none !important;
    min-width: 100%;
    opacity: 1 !important;

    .add-change-img-sec {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 4px;
      height: 100%;

      h5 {
        color: $grey-800;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        color: $grey-800;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.upload-change-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiButtonBase-root {
    padding: 0;
    border: none !important;
    opacity: 1 !important;
    box-shadow: none !important;
    outline: none !important;
    min-height: auto;

    .MuiTouchRipple-root {
      display: none;
    }

    .add-change-img-sec {
      p {
        color: $primary;
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.image-sec {
  .MuiBadge-root {
    .MuiBadge-badge {
      .MuiCircularProgress-root {
        margin-right: 188px;
        margin-top: 188px;
      }
    }
  }
}

.static-pages-container {
  .MuiAccordion-root {
    box-shadow: none;
    padding: 0;
    margin-bottom: 16px;
    border-radius: 10px;

    &:before {
      content: none;
    }

    .MuiAccordionSummary-root {
      padding: 24px;
      background-color: transparent;

      .MuiAccordionSummary-content {
        margin: 0;

        h4 {
          font-size: 1.125rem;
        }

        label {
          margin: 0;
        }

        .MuiFormControlLabel-label {
          color: $grey-400;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          margin: 0;
          letter-spacing: 0;
          margin-left: -5px;
          position: relative;
          top: -2px;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          fill: $grey-800;
          width: 1.5em;
          height: 1.5em;

          path {
            fill: $grey-800;
          }
        }
      }
    }

    .MuiCollapse-wrapper {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;

      .MuiAccordionDetails-root {
        padding: 0;
        &.bg-grey {
          &.section-width-custom {
            padding: 0 !important;
            background: transparent;
            margin: 0;
            max-width: 100%;
          }
        }
      }
    }
  }

  .bg-yellow-sec {
    background-color: $yellow-200;
    background-image: url(../assets/images/about-us-bottom-sec.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.MuiAccordion-root {
      .MuiAccordionSummary-root {
        .MuiAccordionSummary-content {
          .MuiFormControlLabel-label {
            color: $grey-800;
          }
        }
      }
    }
  }

  .bg-black-sec {
    background-color: $grey-800;
    background-image: url(../assets/images/bg-black-sec.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.MuiAccordion-root {
      .MuiAccordionSummary-root {
        .MuiAccordionSummary-expandIconWrapper {
          svg {
            fill: $white;

            path {
              fill: $white;
            }
          }
        }

        .MuiAccordionSummary-content {
          .MuiFormControlLabel-label {
            color: $grey-400;
          }
        }
      }
    }
  }

  ul {
    padding-left: 20px;
  }

  p,
  li {
    font-size: 16px;
    line-height: 1.6;
    &.Mui-error {
      font-size: 12px;
    }
  }

  .no-data-found {
    img {
      max-height: 90px;
    }

    p {
      strong {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.st-block-sec {
  width: 100%;
  display: flex;
  gap: 24px;

  .st-img-sec {
    flex: 0 0 260px;
  }

  .st-contents-sec {
    flex: auto;
  }
}

.alernative-sections {
  .MuiPaper-root:nth-child(odd) {
    .st-block-sec {
      flex-direction: row-reverse;
    }
  }

  &.about-alernative-sections {
    .MuiPaper-root:nth-child(odd) {
      .st-block-sec {
        flex-direction: row;
      }
    }

    .MuiPaper-root:nth-child(even) {
      .st-block-sec {
        flex-direction: row-reverse;
      }
    }
  }
}

.image-sec-90 {
  .add-change-image {
    width: 160px;
    height: 160px;
  }

  .image-sec {
    max-width: 160px;

    .MuiBadge-root {
      img {
        width: 160px !important;
        height: 160px !important;
      }

      .MuiBadge-badge {
        .MuiCircularProgress-root {
          margin-right: 130px;
          margin-top: 105px;
        }
      }
    }
  }

  &.view-image-sec-90 {
    .image-sec {
      .MuiBadge-root {
        img {
          border-radius: 50% !important;
          width: 90px !important;
          height: 90px !important;
        }
      }
    }
  }
}

.image-sec-100 {
  .add-change-image {
    width: 160px;
    height: 160px;
  }

  .image-sec {
    max-width: 160px;

    .MuiBadge-root {
      img {
        width: 160px !important;
        height: 160px !important;
      }

      .MuiBadge-badge {
        .MuiCircularProgress-root {
          margin-right: 130px;
          margin-top: 105px;
        }
      }
    }
  }

  &.view-image-sec-100 {
    .image-sec {
      .MuiBadge-root {
        img {
          border-radius: 50% !important;
          width: 120px !important;
          height: 120px !important;
        }
      }
    }
  }
}

.link-status {
  position: absolute;
  left: -30px;
  top: -22px;
  font-size: 12px !important;
  background-color: $white;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.alternative-sec {
  .MuiPaper-root {
    &:nth-child(even) {
      .alternative-con-sec {
        flex-direction: row-reverse;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.order-change-con {
  .MuiPaper-root {
    &:nth-child(odd) {
      .order-change-inner {
        flex-direction: row-reverse;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.text-grey {
  .MuiBox-root {
    color: $grey-800;
  }
}

.full-img-sec {
  .MuiBadge-root {
    width: 100%;

    img {
      min-width: 100%;
      min-height: 250px;
    }

    .MuiCircularProgress-root {
      margin-right: 250px;
      margin-top: 180px;
    }
  }
}

.MuiStack-root:has(.upload-change-image) {
  img {
    cursor: pointer;
  }
}

.link_opening{
  margin: auto;
  margin-top: 1rem;
  .button_opning {
    color: $black !important;
    background-color: $yellow-200 !important;
    border: 1px solid $yellow-200 !important;
    width: 100%;
    display: flex;
    max-width: 577px;
    justify-content: center;
    align-items: center;
  }
}

.drag_drop {
  color: #4d4d4dd4;
  font-size: 0.8rem;
  font-weight: 400;
  span{
    color: #0062FF;
  }
}

// About, Team, Career pages Ends

// Approval Reason Modal Css Start
body {
  .modal-body-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }
}

.pro-return-reason-modal {
  .MuiDialog-paperWidthSm {
    max-width: 850px;
  }
  .MuiDialogTitle-root {
    padding: 1rem 1.5rem;
    .MuiFormControl-root {
      label {
        display: flex;
        margin: 0;
        .MuiTypography-root {
          margin: 0;
          font-weight: 600;
        }
      }
    }
  }
  .approval-reason-modal {
    width: 100%;
    display: inline-block;

    .MuiDialogContent-root {
      padding: 1rem 1.5rem 1.5rem 1.5rem;
    }

    .table-box {
      width: 100%;
      display: flex;
      max-height: calc(100vh - 280px);
      overflow: auto;
      ul {
        min-width: 700px;
        li {
          border-radius: 16px;
          background: $grey-200;
          width: 100%;
          display: inline-block;
          padding: 20px;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .opacity-box {
            opacity: 0.4;
            pointer-events: none;
          }
          .pro-title-reason {
            h4 {
              color: $grey-800;
              font-size: 16px;
              font-weight: 600;
            }
            .MuiFormControl-root {
              label {
                display: flex;
                margin: 0;
                .MuiTypography-root {
                  margin: 0;
                  font-weight: 600;
                }
              }
            }
          }
          .order-list-reason {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 30px;
            .MuiBox-root {
              label {
                color: $grey-500;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                width: 100%;
                display: inline-block;
                margin: 0 0 8px 0;
              }
              p {
                color: $grey-800;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                min-height: 40px;
                align-items: center;
                display: flex;
              }
              .qty-to-return {
                width: 100%;
                display: flex;
                align-items: center;
                label {
                  display: flex;
                  margin: 0 0 0 -11px;
                  .MuiTypography-root {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                  }
                }
              }
              .plus-mins-qty {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid $white-300;
                border-radius: 2px;
                overflow: hidden;
                margin: 0;
                max-width: 110px;
                border-radius: 6px;
                border: 1px solid $black-200;
          
                .minus,
                .plus {
                  display: inline-block;
                  width: 35px;
                  height: 35px;
                  color: $grey-800;
                  text-align: center;
                  cursor: pointer;
                  font-weight: bold;
                  line-height: 30px;
                  border: none;
                  font-size: 24px;
                  &:disabled {
                    cursor: auto;
                    opacity: 0.5;
                  }
                }
          
                .num {
                  padding: 0 2px;
                  text-align: center;
                }
              }
            }
          }
          .pro-reason-area {
            width: 100%;
            display: inline-block;
            margin-top: 18px;
            button {
              &.add-reason-btn {
                color: $primary;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                padding: 0;
                margin: 0;
                border: none;
                cursor: pointer;
              }
            }
            .add-reason-container {
              width: 100%;
              display: flex;
              margin-top: 20px;
              flex-direction: column;
              gap: 15px;
              .MuiInputBase-root {
                background-color: transparent;
                .MuiSelect-select, textarea {
                  background-color: transparent;
                  border-radius: 12px;
                  font-size: 14px;
                }
                fieldset {
                  border-radius: 12px;
                }
              }
            }
          }
        }
      }
    }

    .MuiDialogActions-root {
      justify-content: center;
      margin-bottom: 0.5rem;

      button {
        font-size: 16px;
      }
    }
  }
}

.order-details-listing {
  li {
    &.MuiListItem-root {
      padding: 15px;
      background-color: #f4f4f4;
      margin-bottom: 15px;
      border-radius: 12px;
    }
  }
}

// Approval Reason Modal Css Ends

// Product Traits Css Start

//added scss new
.title-normal {
  color: $grey-500;
  font-size: 12px;
  font-weight: 400;
}

.radius-0 {
  border-radius: 0;
}

.bg-grey {
  border-radius: 6px;
  background: $grey-200;
  padding: 20px !important;
  .input-custom {
    input {
      text-align: center;
      max-width: 100%;
    }
  }
  button {
    &.primay-btn {
      padding: 13px 36px;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
  }
  .add-delete-btn {
    button {
      svg {
        width: 18px !important;
        height: 18px !important;
        path {
          fill: $red-100;
        }
      }
    }
  }
  form {
    padding: 40px 20px;
    background: $grey-200;
    border-radius: 10px;
  }
  &.section-width-custom {
    background: $grey-200;
    padding: 20px !important;
    max-width: calc(100% - 30px);
    margin: 0 auto 15px;
  }
}

.remove-btn {
  padding: 0;
  svg {
    width: 18px !important;
    height: 18px !important;
    path {
      fill: $red-100;
    }
  }
}

.section-custom-search-field{
  .custom-search-field {
    .MuiInputBase-adornedStart {
      border-radius: 100px;
      background: $grey-100;
      * {
        background-color: transparent !important;
      }
    }
  }
}

.flex-end {
  justify-content: end;
}

.footer-modal {
  .primay-btn {
    padding: 13px 36px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}

.modal-title {
  font-size: 24px;
  font-weight: 700;
}

.color-blue {
  color: $primary !important;
}

.align-center {
  align-items: center;
}

.custom-input-label {
  input {
    border-radius: 48px;
    background: $grey-100;
    &::placeholder {
      color: $grey-500;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.color-blue {
  color: $primary !important;
}

.genetics-strains-sec {
  border-radius: 6px;
  .MuiBadge-root {
    margin-right: 10px;
    margin-bottom: 10px;
    .badge-inner {
      border-radius: 48px;
      background: $white;
      padding: 14px 20px;
      color: $grey-400;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: auto;
      button {
        padding: 0;
        svg {
          max-width: 16px;
          max-height: 16px;
          fill: $black-100;
          color: $black-100 !important;
          fill: $black-25;
          color: $black-25 !important;
        }
      }
    }
  }
}

.select-platform-sec {
  label {
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
}

.select-strain-sec {
  max-width: 480px;
  background-color: $white;
  border-radius: 10px;
  .MuiAutocomplete-root {
    max-width: 100%;
    margin-bottom: 15px;
    .MuiAutocomplete-inputRoot {
      border-radius: 10px;
      .MuiChip-root {
        padding: 0.25rem;
        .MuiChip-label {
          font-size: 12px;
        }
        .MuiSvgIcon-root {
          font-size: 0.75rem !important;
        }
      }
    }
    label {
      color: $primary;
    }
    fieldset {
      border-radius: 10px;
      border: none;
    }
    .MuiAutocomplete-endAdornment {
      button {
        svg {
          fill: $primary;
        }
      }
    }
  }
  button {
    &.primay-btn {
      gap: 0;
      margin: 0 0 20px 20px !important;
      padding: 10px 30px;
    }
  }
}

#strain-autocomplete-listbox {
  li {
    text-transform: capitalize;
  }
}
// Product Traits Css Ends

// Strains Tooltip UI Css Start
.title-with-tooltip {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    max-width: calc(100% - 23px);
    word-break: break-word;
    white-space: normal;
    line-height: normal;
  }
}
// Strains Tooltip UI Css Ends


// Category Page Images Css Start
.add-category-container {
  .add-category-input {
    width: 100%;
    display: flex;
    align-items: start;
    gap: 30px;
    margin-bottom: 20px;
    .category-image-item-2 {
      width: 320px;
      display: flex;
      background-color: $grey-200;
      padding: 10px;
      border-radius: 4px;
      .select-category-image {
        width: 100%;
        display: flex;
        img {
          width: 100%;
        }
      }
    }
    .category-images-container {
      width: calc(100% - 320px);
      display: flex;
      background-color: $grey-200;
      padding: 10px;
      border-radius: 4px;
      .scrollable-sec {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .inner-select-image-item {
          width: calc(20% - 10px);
          display: flex;
          position: relative;
          .select-category-image {
            width: 100%;
            display: flex;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .tick-placement {
            position: absolute;
            left: 5px;
            top: 5px;
          }
        }
      }
    }
  }
}
// Category Page Images Css Ends