$colors: (
    "primary": (100: #0163FE,
        900: #0163FF),
    "black": (25: #100f12,
        50: #5B5B65,
        100: #2B2B2B,
        200: #70707C,
        300: #40404A,
        400: #A9A9B7,
        500: #D0D0DA,
        600: #8A8A98,
        700: #2C2C35,
        800: #1E1E24,
        900: #000000),
    "white": (25:#f0f1f5,
        50: #fdfdfd,
        100: #f0f2f5,
        200: #EFF1F5,
        300: #ACB5BD,
        400: #d2d6da,
        500: #888888,
        600: #f7f7fb,
        700: #F5F5FF,
        800: #E3EBFD,
        900: #ffffff),
    "grey": (25: #eff1f3,
        50:#adb5bd,
        100: #F1F1F1,
        200: #F2F3F7,
        300: #F9F9FA,
        400: #9A9AAF,
        500: #656575,
        600: #555555,
        700: #0062FF1A,
        800: #2E2E3A,
        900: #0F0F13),
    "green": (50: #85bba1,
        100: #24CA49,
        200: #27AA7B,
        300: #1e9a3b,
        400: #46952f,
        500: #14D7A8,
        600: #28B446,),
    "orange": (100: #FE7E07,
        200: #FF7A2F),
    "purple": (100: #A162F7,
        200: #8E0CF5),
    "red": (100: #FD4438,
        200: #fb4940,
        300: #f8584b),
    "yellow": (100: #FFDB1E,
        200: #FFD100,
        300: #F5C20A),
);

$prefix_text: "text";
$prefix_bg: "bg";

@each $color, $shades in $colors {
    @each $shade, $value in $shades {
        .#{$prefix_text}-#{$color}-#{$shade} {
            color: $value !important;
        }
    }
}

@each $color, $shades in $colors {
    @each $shade, $value in $shades {
        .#{$prefix_bg}-#{$color}-#{$shade} {
            background-color: $value !important;
        }
    }
}

// Font Sizes
.fs-xs-small {
    font-size: 10px !important;
    line-height: 1.15 !important;
}
.fs-x-small {
    font-size: 12px !important;
    line-height: 1.15 !important;
}
.fs-small {
    font-size: 14px !important;
    line-height: 1.15 !important;
}
.fs-medium {
    font-size: 16px !important;
}
.fs-large {
    font-size: 18px !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-block {
    display: inline-block !important;
}

.jc-center {
    justify-content: center !important;
}

.jc-end {
    justify-content: end !important;
}

.jc-s-btwn {
    justify-content: space-between !important;
}

.f-wrap {
    flex-wrap: wrap !important;
}

.align-item-center {
    align-items: center !important;
}

.align-item-top {
    align-items: flex-start !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.gap-1 {
    gap: 2.5rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-3 {
    gap: 1rem;
}

.gap-4 {
    gap: 2rem;
}

.gap-5 {
    gap: 3rem;
}

.position-relative {
    position: relative !important;
}

.d-block {
    display: block !important;
}

.gap-0 {
    gap: 0 !important;
}
.gap-1 {
    gap: .25rem !important;
}
.gap-2 {
    gap: .5rem !important;
}
.gap-3 {
    gap: 1rem !important;
}
.gap-4 {
    gap: 1.5rem !important;
}
.gap-5 {
    gap: 3rem !important;
}

.cur-pointer {
    cursor: pointer !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.min-width-auto {
    min-width: auto !important;
}

.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.border-radius-50 {
    border-radius: 50% !important;
}

.border-none {
    border: 0 !important;
}

.text-transform-none {
    text-transform: none !important;
}

.text-transform-capitalize {
    text-transform: capitalize !important;
}

button {
    &:focus {
        box-shadow: none !important;
    }
}

.font-w-500 {
    font-weight: 500 !important;
}

.font-w-600 {
    font-weight: 600 !important;
}

.text-grey-color {
    color: #656575;
}

.box-shadow-none {
    box-shadow: none;
}

.line-height-normal {
    line-height: normal !important;
}

.text-line-through {
    text-decoration: line-through !important;
}

.object-fit-contain {
    object-fit: contain !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

.text-10 {
    font-size: 10px !important;
}

.text-12 {
    font-size: 12px !important;
}

.text-14 {
    font-size: 14px !important;
}

.text-16 {
    font-size: 16px !important;
}

.text-18 {
    font-size: 18px !important;
}

.text-20 {
    font-size: 20px !important;
}

.text-24 {
    font-size: 24px !important;
}

.white-space-nowrap {
    white-space: nowrap;
}

.lh-normal {
    line-height: normal !important;
}

.box-sizing-border {
    box-sizing: border-box;
}

.disabled {
    pointer-events: none;

    input {
        pointer-events: none;
        color: rgba(0, 0, 0, 0.38);
        background-color: $grey-100 !important;
    }
}

.custom-checkbox-ui,
.custom-checkbox-ui>div {
    width: 100%;
    flex-direction: row;
    display: flex !important;
}

.ellipsis-one {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.error {
    font-size: 12px;
    color: #ff0000;
    margin-bottom: 0;
    opacity: 0.7;
    margin: 0;
  }
  .icon_tick{
    color: #3135cc;
    font-size: 1rem;
    height: 4em !important;
    width: 5em !important;
    opacity: 0;
    transform: scale(0.7);
    animation: fadeInScale 0.8s forwards;
  }
  .icon_cross{
    color: #ff0000;
    font-size: 1rem;
    height: 4em !important;
    width: 5em !important;
    opacity: 0;
    transform: scale(0.7);
    animation: fadeInScale 0.8s forwards;
  }
  @keyframes fadeInScale {
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }