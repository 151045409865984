@media (min-width: 768px) {
    body {
        .quill-text-editor-view {
            h1 { 
                font-size: 28px; 
            }
            h2 { 
                font-size: 24px; 
            }
            h3 { 
                font-size: 20px; 
            }
        }
    }
}

@media (min-width: 991px) {
    .auth-pg {
        &.reset-password {
            position: relative;
            .auth-banner-img {
                position: absolute;
                inset: 0;
                max-height: 100%;
                z-index: -1;
                border-radius: 0;
            }
        }
    }
}

@media (min-width: 1401px) {
    body {
        .quill-text-editor-view {
            h1 { 
                font-size: 32px; 
            }
            h2 { 
                font-size: 28px; 
            }
            h3 { 
                font-size: 24px; 
            }
        }
    }
}

@media (min-width: 1500px) {
    .add-category-container {
        .add-category-input {
            .category-image-item-2 {
                width: 420px;
            }
            .category-images-container {
                width: calc(100% - 420px);
            }
        }
    }
}

@media (max-width: 1800px) {
    header {
        &.header-sec {    
            .cus-toolbar {
                button {
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;
                    font-size: 0.9rem;
                }
                .nav-right {
                    button {
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                        font-size: 0.9rem;
                        min-height: 34px;
                        p {
                            font-size: 0.9rem;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .table-pg {
        .custom-search-field {
            &.dark-field {
                width: 180px !important;
                .MuiInputBase-formControl {
                    min-width: 180px;
                }
            }
        }
        .delivery-speed-field {
            .MuiFormControl-root {
                width: 180px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .dark-mode {
        .text-10 {
            font-size: 8px !important;
        }        
        .text-12 {
            font-size: 10px !important;
        }        
        .text-14 {
            font-size: 12px !important;
        }        
        .text-16 {
            font-size: 14px !important;
        }        
        .text-18 {
            font-size: 16px !important;
        }
        .text-20 {
            font-size: 18px !important;
        }        
        .text-24 {
            font-size: 20px !important;
        }
    }

    .hd-wrapper {
        .hd-right-wrapper {
            .driver-details-drawer {
                .driver-info-tabs {      
                    .text-24 {
                        font-size: 20px !important;
                    }
                }
            }
        }
        .cus-filter-sec {      
            .filter-btns {
                button {
                    &.filter-clear-all {
                        font-size: 12px;
                    }
                }
            }
        }
    }
      
    .hd-tabs {
        .MuiTabs-root {
            button {
                font-size: 14px;
            }
        }
        &.hd-sub-tabs {
            .MuiTabs-root {
                .MuiTabs-flexContainer {
                    button {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    
    .time-task-driver {
        .select-rep-sec {
            .select-calendar, .select-with-radio {    
                p {
                    font-size: 12px;
                }
            }
        }
    }
    
    body {
        .MuiPopover-root {
            .MuiPaper-root {
                &:has(.hd-dropdown-sec) {
                    p {
                        font-size: 12px;
                    }        
                    .popover-dropdown {
                        .MuiTypography-root {
                            font-size: 12px;
                        }
                    }
                }
            }
        }    
        .cus-drawer {   
            .text-10 {
                font-size: 8px !important;
            }        
            .text-12 {
                font-size: 10px !important;
            }        
            .text-14 {
                font-size: 12px !important;
            }        
            .text-16 {
                font-size: 14px !important;
            }        
            .text-18 {
                font-size: 16px !important;
            }
            .text-20 {
                font-size: 18px !important;
            }        
            .text-24 {
                font-size: 20px !important;
            } 
            .drawer-bottom-btns {    
                button {
                    font-size: 12px;
                }
            }
            .drawer-radio-sec,
            .drawer-checkbox-sec {        
                label {
                    .MuiTypography-root {
                        font-size: 12px;
                    }
                }
            }
        }    
    }
        
    .content-wrapper {
        .contents-right {
            h5 {
                font-size: 18px;
            }            
        }
    }
      
    .hd-search-filter {      
        button {
            &.hd-filter-dropdown {
                font-size: 12px;      
                svg {
                    font-size: 2rem !important;
                }
            }
        }
    }
      
    .hd-google-suggestions {
      
        .autocomplete-dropdown-container {
      
          >div,
          .suggestion-item {
            font-size: 12px;
      
            span {
              font-size: 12px;
            }
          }
        }
    }

}

@media (max-width: 990px) {
    .auth-pg {
        &.reset-password {
            min-height: auto;
            .auth-banner-img {
                border-radius: 0;
                max-height: 480px;
                object-position: left;
            }
        }
    }
}

@media (max-width: 767px) {
    .auth-pg {
        &.reset-password {
            .auth-banner-img {
                max-height: 380px;
            }
            h2 {
                font-size: 1.5rem;
            }
            .logo {
                display: none;
            }
        }
    }
}