// Colors
$primary: #0163FF;
$primary-100: #0163FE;
$primary-900: #0163FF;

$black: #000000;
$black-25:#100f12;
$black-50: #5B5B65;
$black-100: #2B2B2B;
$black-200: #70707C;
$black-300: #40404A;
$black-400: #A9A9B7;
$black-500: #D0D0DA;
$black-600: #8A8A98;
$black-700: #2C2C35;
$black-800: #1E1E24;
$black-900: #000000;

$white: #ffffff;
$white-25: #f0f1f5;
$white-50: #fdfdfd;
$white-100: #f0f2f5;
$white-200: #EFF1F5;
$white-300: #ACB5BD;
$white-400: #d2d6da;
$white-500: #888888;
$white-600: #f7f7fb;
$white-700: #F5F5FF;
$white-800: #E3EBFD;
$white-900: #ffffff;

$grey-25:#eff1f3;
$grey-50: #adb5bd;
$grey-100: #F1F1F1;
$grey-200: #F2F3F7;
$grey-300: #F9F9FA;
$grey-400: #9A9AAF;
$grey-500: #656575;
$grey-600: #555555;
$grey-700: #0062FF1A;
$grey-800: #2E2E3A;
$grey-900: #0F0F13;

$green-50: #85bba1;
$green-100: #24CA49;
$green-200: #27AA7B;
$green-300: #1e9a3b;
$green-400: #46952f;
$green-500: #14D7A8;
$green-600: #28B446;

$orange-100: #FE7E07;
$orange-200: #FF7A2F;

$purple-100: #A162F7;
$purple-200: #8E0CF5;

$red-100: #FD4438;
$red-200: #fb4940;
$red-300: #f8584b;

$yellow-100: #FFDB1E;
$yellow-200: #FFD100;
$yellow-300: #F5C20A;
