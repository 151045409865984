@import "./variables.scss";
@import "./common.scss";
@import "./custom.scss";
@import "./breakpoints.scss";

body {
    scrollbar-color: $white;
    background-color: $grey-200 !important;
}
::-webkit-scrollbar {
    background-color: $white;
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px $grey-400;
    background-color: $white;
}
::-webkit-scrollbar-thumb {
    background-color: $grey-500;
}
